/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import { Button, CircularProgress, Grid, MenuItem, Select, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import '../../assets/css/editbooking.css'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { Alert } from '@material-ui/lab'
import styles from '../../assets/jss/material-dashboard-react/views/editBookingStyle'
import { getDetailBooking, updateBookingUser } from '../../api/booking'
import { AUTHEN_TOKEN, DD_MM_YYYY, hh_mm, HH_mm_DD_MM_YYYY } from '../../constants/define'
import { getProfileApi } from '../../api'
import MultiInputCourse from './MultiInputCourse'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import DialogChooseBookingTime from './components/DialogChooseBookingTime'
import {
  checkArrayDayIncludeTime,
  checkTimeWithBookingBefore,
  checkTimeWithSpecialDay,
  generateRangeNumber,
  generateRangeTime,
  getOpenTime,
} from '../../commons'
import CardHeader from '../../components/Card/CardHeader'

const useStyles = makeStyles(styles)

const success_type = {
  error: 'somethingWrong',
  success: 'booking.success',
  none: '',
}

const EditBooking = props => {
  const [isLoading, setLoading] = useState(true)
  const [bookingInfo, setBookingInfo] = useState(null)
  const [restaurantInfo, setRestaurantInfo] = useState(null)
  const [courseData, setCourseData] = useState([])
  const [rangeQuantity, setRangeQuantity] = useState([])
  const [quantity, setQuantity] = useState(0)
  const refDialog = React.useRef()
  const [reason, setReason] = useState('')
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [success, setSuccess] = useState(success_type.none)

  const [dateBooking, setDateBooking] = useState({
    date: moment(),
    time: '',
  })
  const [errorDate, setErrorDate] = useState('')
  const [errorReason, setErrorReason] = useState('')
  // const [timeBooking, setTimeBooking] = useState('')

  const { match } = props
  const { id } = match.params

  const classes = useStyles()
  const language = useSelector(state => state.languageReducer.language)
  let check = {}
  /**
   * Function xu ly lay Booking data
   */
  useEffect(() => {
    onGetData()
  }, [])

  /**
   * Get booking detail
   */
  const onGetData = async () => {
    try {
      const payload = {
        authen: localStorage.getItem(AUTHEN_TOKEN),
        bookingId: id,
      }
      const authenToken = localStorage.getItem(AUTHEN_TOKEN)
      const [booking, profile] = await Promise.all([
        await getDetailBooking(payload),
        await getProfileApi(authenToken),
      ])
      if (booking.isSuccess && profile.status === 'success') {
        check = {}
        setDateBooking({
          date: moment(booking?.data?.booked_at).format(DD_MM_YYYY),
          time: moment(booking?.data?.booked_at).format(hh_mm),
        })
        // setTimeBooking(moment(booking?.data?.booking_at).format(hh_mm))
        setBookingInfo(booking?.data || {})
        setRestaurantInfo(profile.data)
        const rangeTime = generateRangeTime([
          getOpenTime(moment(booking?.data?.booked_at), profile.data?.open_time?.am),
          getOpenTime(moment(booking?.data?.booked_at), profile.data?.open_time?.pm),
        ])
        setRangeQuantity(generateRangeNumber(profile?.data?.min_diner, profile?.data?.max_diner))
        setQuantity(booking?.data?.quantity || 1)
        setReason(booking?.data.restaurant_note)
        setCourseData(Array.isArray(booking?.data.courses) ? booking?.data.courses[0] : undefined)
        if (refDialog.current) {
          refDialog.current.setData(rangeTime)
        }
        setLoading(false)
      }
    } catch (e) {
      console.warn(e)
    }
  }

  const checkEnableTime = (time, renew) => {
    if (typeof check[time] !== 'boolean' || renew) {
      if (
        !checkTimeWithBookingBefore(
          `${time}, ${moment(dateBooking.date, DD_MM_YYYY).format(DD_MM_YYYY)}`,
          restaurantInfo?.book_before
        )
      ) {
        check[time] = false
        return false
      }

      if (
        checkArrayDayIncludeTime(
          moment(dateBooking.date, DD_MM_YYYY),
          restaurantInfo?.not_booking_day
        )
      ) {
        check[time] = false
        return false
      }

      if (restaurantInfo?.non_reservable_datetime) {
        if (
          checkTimeWithSpecialDay(
            time,
            restaurantInfo?.non_reservable_datetime[
              moment(dateBooking.date, DD_MM_YYYY).format(DD_MM_YYYY)
            ]
          )
        ) {
          check[time] = true
        } else {
          check[time] = false
        }
        return check[time]
      }
      check[time] = true
    }
    return check[time]
  }

  /**
   * Set courses data sau khi nhan booking Data
   */
  useEffect(() => {
    onSetCourseData()
  }, [bookingInfo])

  /**
   * Function set courses data
   * @param {*} value
   */
  const onSetCourseData = value => {
    setCourseData(value)
  }

  /**
   * Component render info
   */
  const renderInfo = React.useCallback(({ label, value }) => {
    return (
      <Grid container style={{ padding: '8px 12px' }}>
        <Grid item md={2}>
          <div className="txt-label-key">{label}</div>
        </Grid>
        <Grid item md={8}>
          <div className="txt-info">{value}</div>
        </Grid>
      </Grid>
    )
  }, [])

  /**
   * Component render User info
   */
  const renderUser = React.useCallback(() => {
    return (
      <Grid item md={12}>
        <div className="txt-label">{language.t('customer.info')}</div>
        {renderInfo({ label: language.t('customer.name'), value: bookingInfo?.name || '' })}
        {renderInfo({ label: language.t('customer.email'), value: bookingInfo?.email || '' })}
        {renderInfo({ label: language.t('customer.phone'), value: bookingInfo?.phone || '' })}
        {renderInfo({ label: language.t('note'), value: bookingInfo?.note || '' })}
      </Grid>
    )
  }, [bookingInfo])

  /**
   * func sau khi chon ngay dat ban
   * nếu chọn ngày trước đó. thì bỏ qua
   * chọn ngày khác sẽ lưu lại time mới, set mảng data cho dialog
   * @param {*} date
   */
  const onChangeTimePicker = date => {
    const newDate = moment(date).format(DD_MM_YYYY)

    if (newDate === dateBooking.date) return

    setDateBooking({
      date: moment(date).format(DD_MM_YYYY),
      time: '',
    })
    check = {}
    const rangeTime = generateRangeTime([
      getOpenTime(moment(newDate, DD_MM_YYYY), restaurantInfo?.open_time?.am),
      getOpenTime(moment(newDate, DD_MM_YYYY), restaurantInfo?.open_time?.pm),
    ])

    if (refDialog) {
      refDialog.current.setData(rangeTime)
    }
  }

  const onChooseTime = time => {
    setDateBooking(s => ({ ...s, time }))
    setErrorDate('')
  }

  // func khi click vao time booking => show modal de chọn thời gian booking
  const onClickTime = () => {
    if (refDialog.current) {
      refDialog.current.show()
    }
  }

  /**
   * phan render cho muc sửa ngày giờ đặt bàn
   */
  const renderTimeBooking = React.useCallback(() => {
    return (
      <Grid container style={{ padding: '8px 12px' }}>
        <Grid item md={2}>
          <div className="txt-label-key">{language.t('timeBooking')}</div>
        </Grid>
        <Grid item md={2}>
          <DatePicker
            error={false}
            helperText=""
            ampm={false}
            value={moment(dateBooking.date, DD_MM_YYYY)}
            format={DD_MM_YYYY}
            minDate={moment()}
            maxDate={moment().add('1', 'y')}
            onChange={onChangeTimePicker}
            style={{ width: '55%', fontSize: '12px' }}
            className="date-picker-component"
          />
        </Grid>
        <div onClick={onClickTime} area-hidden="true" className="txt-time-booking">
          {dateBooking.time || language.t('choose_time')}
        </div>
        <div style={{ fontSize: 12, color: 'red', marginLeft: 12 }}>
          {errorDate ? language.t(errorDate) : ''}
        </div>
        <Grid />
      </Grid>
    )
  }, [dateBooking, restaurantInfo, errorDate])

  const renderQuantity = React.useCallback(() => {
    return (
      <Grid item md={8}>
        <div className="txt-info">
          <Select
            style={{ width: '400px' }}
            value={quantity}
            onChange={er => setQuantity(er.target.value)}
          >
            {rangeQuantity.map(r => {
              return (
                <MenuItem key={r.key} value={r.value}>
                  {r.label}
                </MenuItem>
              )
            })}
          </Select>
        </div>
      </Grid>
    )
  }, [rangeQuantity, quantity])

  const renderReasonChange = React.useCallback(() => {
    return (
      <Grid item md={8}>
        <div className="txt-info">
          <TextField
            style={{ width: '400px' }}
            variant="outlined"
            multiline
            rows={4}
            value={reason}
            onChange={e => {
              setErrorReason('')
              setReason(`${e.target.value}`.slice(0, 1024))
            }}
          />
          <div style={{ fontSize: 12, color: 'red' }}>
            {errorReason ? language.t(errorReason) : ''}
          </div>
        </div>
      </Grid>
    )
  }, [reason, errorReason])

  const onSubmit = () => {
    if (loadingSubmit || errorDate || errorReason) return

    let checkvalidate = false
    if (!dateBooking.time) {
      setErrorDate('booking.time_required')
      checkvalidate = true
    }

    if (!reason) {
      setErrorReason('booking.reason_required')
      checkvalidate = true
    }

    if (checkvalidate) return

    const param = {
      restaurant_note: reason,
    }
    let checkChange = false

    if (bookingInfo?.restaurant_note !== reason) {
      checkChange = true
    }

    if (
      moment(bookingInfo.booked_at).format(HH_mm_DD_MM_YYYY) !==
      `${dateBooking.time}, ${dateBooking.date}`
    ) {
      param.booked_at = `${dateBooking.time} ${dateBooking.date}`
      checkChange = true
    }
    if (bookingInfo.quantity !== quantity) {
      param.quantity = quantity
      checkChange = true
    }

    if (Array.isArray(bookingInfo.courses) && courseData) {
      const oldCourse = bookingInfo.courses[0]
      if (oldCourse || courseData) {
        if (!oldCourse) {
          param.newCourse = courseData
          checkChange = true
        } else if (!courseData) {
          param.oldCourse = oldCourse
          checkChange = true
        } else if (oldCourse.id !== courseData.id) {
          param.newCourse = courseData
          // param.oldCourse = oldCourse
          checkChange = true
        }
      }
    }

    if (Array.isArray(bookingInfo.courses) && bookingInfo.courses.length > 0 && !courseData) {
      const oldCourse = bookingInfo.courses[0]
      param.oldCourse = oldCourse
      checkChange = true
    }

    if (checkChange) {
      setLoadingSubmit(true)
      updateBookingUser({
        authen: localStorage.getItem(AUTHEN_TOKEN),
        id,
        ...param,
      })
        .then(r => {
          if (r.success) {
            setLoadingSubmit(false)
            setSuccess(success_type.success)
            setBookingInfo(r.data)
          } else {
            setLoadingSubmit(false)
            setSuccess(success_type.error)
          }
        })
        .finally(() => {
          setTimeout(() => {
            setSuccess(success_type.none)
          }, 3000)
        })
    }
  }

  const renderSubmit = () => {
    return (
      <Grid container style={{ padding: '8px 12px' }}>
        <Grid item md={2} />
        <Grid item md={8}>
          <Button
            variant="contained"
            color="inherit"
            style={{
              backgroundColor: '#F7941D',
              color: 'white',
            }}
            onClick={onSubmit}
          >
            {loadingSubmit ? (
              <CircularProgress size={24} color="inherit" style={{ color: 'white' }} />
            ) : null}
            {language.t('save')}
          </Button>
        </Grid>
      </Grid>
    )
  }

  /**
   * Component render booking info
   */
  const renderBookingInfo = () => {
    return (
      <Grid item md={12} style={{ marginTop: 24 }}>
        <div className="txt-label">{language.t('bookingInfo')}</div>
        {renderTimeBooking()}
        <Grid container style={{ padding: '8px 12px' }}>
          <Grid item md={2}>
            <div className="txt-label-key">{language.t('numberBooking')}</div>
          </Grid>
          {renderQuantity()}
        </Grid>
        <Grid container style={{ padding: '8px 12px' }}>
          <Grid item md={2}>
            <div className="txt-label-key">{language.t('course')}</div>
          </Grid>
          <Grid item md={8}>
            {bookingInfo && (
              <MultiInputCourse
                courseData={bookingInfo?.courses || []}
                onSetCourseData={onSetCourseData}
              />
            )}
          </Grid>
        </Grid>
        <Grid container style={{ padding: '8px 12px' }}>
          <Grid item md={2}>
            <div className="txt-label-key">{language.t('noteEdit')}</div>
          </Grid>
          {renderReasonChange()}
        </Grid>
        {renderSubmit()}
      </Grid>
    )
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
      <div className={classes.container}>
        {/* <Card> */}
        <CardHeader plain color="primary" style={{ marginBottom: 20, marginTop: 0 }}>
          <h4 className={classes.cardTitleWhite} style={{ margin: 12 }}>
            {language.t('editBooking')}
          </h4>
        </CardHeader>
        {/* </Card> */}
        {success !== success_type.none ? (
          <Alert variant="outlined" severity={success === success_type.error ? 'error' : 'success'}>
            {language.t(success)}
          </Alert>
        ) : null}
        {isLoading ? (
          <div className={classes.contentLoader}>
            <CircularProgress
              size={40}
              color="inherit"
              style={{ color: '#000', marginBottom: '20px' }}
            />
          </div>
        ) : restaurantInfo ? (
          <>
            {renderUser()}
            {renderBookingInfo()}
          </>
        ) : (
          <div />
        )}
        <DialogChooseBookingTime
          checkEnableTime={checkEnableTime}
          onChooseTime={onChooseTime}
          ref={refDialog}
        />
      </div>
    </MuiPickersUtilsProvider>
  )
}

EditBooking.propTypes = {
  match: PropTypes.any,
}

export default EditBooking

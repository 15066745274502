/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useCallback } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { getListCourse } from '../../api/course'
import { AUTHEN_TOKEN } from '../../constants/define'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}))

function MultiInputCourse(props) {
  const { courseData, onSetCourseData } = props
  const [listCourse, setListCourse] = useState([])

  useEffect(() => {
    onGetListCourse()
  }, [])

  /**
   * Function lay list courses cua nha hang
   */
  const onGetListCourse = async () => {
    try {
      const payload = {
        authen: localStorage.getItem(AUTHEN_TOKEN),
        page: 1,
        limit: 1000,
      }
      const result = await getListCourse(payload)
      if (result.isSuccess) {
        setListCourse(result.data)
      }
    } catch (e) {
      console.warn(e)
    }
  }

  const onCourseChange = (event, value) => {
    if (typeof onSetCourseData === 'function') {
      onSetCourseData(listCourse[listCourse.findIndex(i => i.id === value)])
    }
  }

  const renderInput = useCallback(() => {
    if (listCourse.length <= 0) return null

    const defaultDataValue = Array.isArray(courseData) && courseData.length > 0 ? courseData[0] : {}

    return (
      <Autocomplete
        id="tags-standard"
        options={listCourse.map(i => i.id)}
        getOptionLabel={option => {
          return listCourse[listCourse.findIndex(i => `${i.id}` === `${option}`)]?.name || ''
        }}
        style={{ width: '400px' }}
        defaultValue={defaultDataValue.id}
        onChange={onCourseChange}
        // getOptionSelected={onGetValueSelected}
        renderInput={params => {
          return (
            <TextField
              {...params}
              variant="outlined"
              label={language.t('course')}
              placeholder={language.t('editCourse')}
            />
          )
        }}
      />
    )
  }, [listCourse, courseData])

  const classes = useStyles()
  const language = useSelector(state => state.languageReducer.language)
  return <div className={classes.root}>{renderInput()}</div>
}

MultiInputCourse.propTypes = {
  courseData: PropTypes.any,
  onSetCourseData: PropTypes.func,
}

export default MultiInputCourse

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'
import _ from 'lodash'
import { connect } from 'react-redux'
import styles from '../assets/jss/material-dashboard-react/layouts/loginStyle'
import bgImage from '../assets/img/cover.png'
import { signInApi } from '../api'
import '../assets/css/Login/styles.css'
import { validateEmail, validatePassword } from '../commons'
import logo from '../assets/img/logo.png'
import { messaging } from '../setup/init-fcm'
import { DEVICE_TOKEN, AUTHEN_TOKEN } from '../constants/define'
import LanguageBox from '../components/LanguageBox'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      errorPassword: undefined,
      errorEmail: undefined,
      errorText: null,
      isLoading: false,
    }
    // this.onChangeFormData = _.debounce(this.onChangeFormData, 200)
  }

  componentDidMount() {
    const { language } = this.props
    document.title = language.t('pageTitle')
    const { history } = this.props
    const authenToken = localStorage.getItem(AUTHEN_TOKEN)
    if (authenToken) {
      history.push('/admin/user')
    }
    if (messaging) {
      messaging
        .requestPermission()
        .then(async () => {
          const token = await messaging.getToken()
          localStorage.setItem(DEVICE_TOKEN, token)
        })
        .catch(() => {
          // console.log('Unable to get permission to notify.', err)
        })
    }
  }

  /**
   * handle change form data
   */
  onChangeFormData = event => {
    const { value, name } = event.target
    this.setState({
      errorText: null,
    })
    if (name === 'email' && !_.isUndefined(value)) {
      this.setState({ email: value, errorEmail: validateEmail(value) })
    }
    if (name === 'password' && !_.isUndefined(value)) {
      this.setState({
        password: value,
        errorPassword: validatePassword(value),
      })
    }
  }

  /**
   * Handle press key
   */
  handlePressKey = ev => {
    if (ev.key === 'Enter') {
      ev.preventDefault()
      this.onSubmitForm()
    }
  }

  /**
   * Handle submit form data
   */
  onSubmitForm = async () => {
    try {
      const { email, password } = this.state
      const { history } = this.props
      if (email === '' || password === '') {
        this.setState({
          errorText: 'validateField',
        })
        return
      }

      if (validateEmail(email) !== '') {
        return
      }

      if (validatePassword(password) !== '') {
        return
      }
      const deviceToken = localStorage.getItem(DEVICE_TOKEN)
      const payload = {
        email,
        password,
        deviceToken,
      }
      this.setState({
        isLoading: true,
      })

      const data = await signInApi(payload)
      console.log('Login -> onSubmitForm -> data', data)
      if (data.status === 'success') {
        if (data.data.reservable) {
          localStorage.setItem(AUTHEN_TOKEN, data.data.authentication_token)
          history.push('/admin/user')
        } else {
          this.setState({
            errorText: 'errorRequiredLogin',
          })
        }
      } else {
        this.setState({
          errorText: data.message,
        })
      }

      this.setState({
        isLoading: false,
      })
    } catch (e) {
      // console.warn(e)
    }
  }

  render() {
    const { classes, language } = this.props
    const { email, password, errorEmail, errorPassword, errorText, isLoading } = this.state
    // console.log({errorEmail, errorPassword});
    const formClasses = classnames('form-view', classes.formView)
    return (
      <div className={classes.wrapper} style={{ backgroundImage: `url(${bgImage})` }}>
        <div style={{ width: '100%', padding: '20px 20px 0 0', boxSizing: 'border-box' }}>
          <LanguageBox customClass={classes.languageBox} />
        </div>
        <form onSubmit={this.onSubmitForm} className={formClasses}>
          <div className={classes.viewOverlay}>
            <img style={{ width: '80px' }} alt="logo" src={logo} />
            <span className="login-label">{language.t('hello')}</span>
          </div>

          <TextField
            error={!!errorEmail}
            helperText={errorEmail && language.t(errorEmail)}
            className={classes.input}
            value={email}
            style={{ marginTop: '150px' }}
            id="outlined-search"
            label={language.t('email')}
            type="search"
            variant="outlined"
            name="email"
            onChange={this.onChangeFormData}
            onKeyPress={this.handlePressKey}
          />
          <TextField
            onKeyPress={this.handlePressKey}
            error={!!errorPassword}
            helperText={errorPassword && language.t(errorPassword)}
            className={classes.input}
            value={password}
            id="outlined-search"
            label={language.t('password')}
            type="password"
            name="password"
            variant="outlined"
            onChange={this.onChangeFormData}
          />
          {errorText && <p className={classes.errorText}>{errorText && language.t(errorText)}</p>}

          <Button
            variant="contained"
            color="primary"
            className="btn-login"
            onClick={this.onSubmitForm}
            style={{ backgroundColor: '#F7941D' }}
          >
            {isLoading ? <CircularProgress size={30} color="inherit" /> : language.t('signIn')}
          </Button>
        </form>
      </div>
    )
  }
}

Login.propTypes = {
  history: PropTypes.any,
  classes: PropTypes.any,
  language: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    language: state.languageReducer.language,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Login))

/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Person from '@material-ui/icons/Person'
import List from '@material-ui/icons/List'
import BadgNotification from './components/Badge'
import { TYPE_NOTIFI } from './constants/define'
// core components/views for Admin layout
// import DashboardPage from './views/Dashboard/Dashboard'
import UserProfile from './views/UserProfile/UserProfile'
import CourseBooking from './views/CourseBooking'
import NewBookingCourse from './views/CourseBooking/NewBookingCourse'
import BookingList from './views/BookingList'
import ListNotification from './views/Notification/ListNotification'

import EditBooking from './views/BookingList/Edit'

const brandRouters = [
  {
    path: '/course/edit/:id',
    name: 'editCourse',
    component: NewBookingCourse,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/course/create',
    name: 'createCourse',
    component: NewBookingCourse,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/booking/edit/:id',
    name: 'editBooking',
    component: EditBooking,
    layout: '/admin',
    invisible: true,
  },
]

const dashboardRoutes = [
  {
    path: '/user',
    name: 'storeInfo',
    icon: Person,
    component: UserProfile,
    layout: '/admin',
  },
  {
    path: '/courseList',
    name: 'bookingCourseList',
    icon: List,
    component: CourseBooking,
    layout: '/admin',
  },
  {
    path: '/bookingList',
    name: 'bookingList.title',
    icon: List,
    component: BookingList,
    layout: '/admin',
  },
  {
    path: '/listNotification',
    name: 'notification',
    icon: BadgNotification,
    component: ListNotification,
    layout: '/admin',
    type: TYPE_NOTIFI,
  },
]

const mainRouters = [...dashboardRoutes, ...brandRouters]

export default mainRouters

export default {
  pageTitle: 'Capichi Booking',
  storeInfo: 'Thông tin nhà hàng',
  profileName: 'Hồ sơ',
  logOut: 'Đăng xuất',
  signIn: 'Đăng nhập',
  hello: 'Xin Chào',
  validatePassword: 'Vui lòng nhập mật khẩu từ 8 - 25 kí tự',
  validateField: 'Hãy điền đầy đủ thông tin cho tất cả các mục.',
  password: 'Mật khẩu',
  name: 'Tên nhà hàng',
  address: 'Địa chỉ',
  status: 'Trạng thái',
  shippingFee: 'Phí vận chuyển',
  openTime: 'Thời gian mở cửa',
  image: 'Hình ảnh',
  email: 'Địa chỉ email',
  note: 'Ghi chú',
  delete: 'Xoá',
  addNew: 'Thêm mới',
  time: {
    min: 'Thời gian đóng cửa phải lớn hơn thời gian mở cửa.',
    max: 'Thời gian mở cửa không phù hợp, hãy kiểm tra lại.',
    open: 'Vui lòng nhập thời gian mở cửa.',
    close: 'Vui lòng nhập thời gian đóng cửa.',
  },
  result: {
    error: 'Cập nhật thông tin không thành công. Vui lòng thử lại sau.',
    success: 'Thông tin cửa hàng của bạn đã được cập nhật thành công.',
  },
  price: 'Giá',
  errorCustomer: {
    validateEmail: 'Vui lòng nhập định dạng email hợp lệ.',
  },
  save: 'Lưu',
  cancel: 'Huỷ',
  edit: 'Chỉnh Sửa',
  foodDetail: {
    unit: 'Đơn vị giá',
    delete: 'Xoá set món ăn',
    confirmDelete: 'Bạn có chắc chắn muốn set xoá món ăn này không?',
  },
  ok: 'Đồng ý',
  validateImage: 'Định dạng yêu cầu là JPG/PNG và kích thước < 10MB.',
  orderResult: {
    error: 'Cập nhật thông tin đơn hàng không thành công. Vui lòng thử lại sau.',
    error1: 'Cập nhật thông tin không thành công. Vui lòng thử lại sau.',
    success: 'Thông tin đơn hàng đã được cập nhật thành công.',
    timeout: 'Trạng thái đơn hàng không cho phép đặt ship lại.',
    delivery: 'Trạng thái đơn hàng không cho phép huỷ ship.',
  },
  somethingWrong: 'Đã xảy ra lỗi. Vui lòng thử lại sau.',
  active: 'Hiển thị',
  menuValidate: {
    validatePrice: 'Số tiền cho món ăn ít nhất 1 kí tự, tối đa 15 kí tự.',
    requiredName: 'Tên cho món ăn ít nhất 1 kí tự, tối đa 200 kí tự.',
    requiredImage: 'Bạn cần phải thêm ảnh cho món ăn.',
    requireCategory: 'Vui lòng chọn danh mục cho món ăn.',
  },
  addNewImage: 'Thêm ảnh',
  notification: 'Thông báo',
  notifyList: 'Danh sách thông báo',
  notifyDescription: 'Thống kê tất cả thông báo',
  notifySku: 'Mã thông báo',
  notifyTitle: 'Tiêu đề',
  notifyEmpty: 'Hiện chưa có thông báo',
  detail: 'Chi tiết',
  rowPerPage: 'Số dòng trên một trang',
  error300: 'Máy chủ không thể thực hiện yêu cầu của bạn',
  error500: 'Không thể kết nối đên máy chủ. Vui lòng thử lại sau.',
  error401: 'Vui lòng đăng nhập để thực hiện chức năng.',
  error404: 'Nội dung yêu cầu không thể tìm thấy hoặc đã bị xoá. Vui lòng thử lại sau.',
  error400: 'Nội dung không tồn tại. Vui lòng thử lại sau.',
  noteConfirmPhone: 'Vui lòng xác nhận số điện thoại trước khi chọn địa chỉ',
  retry: ' Thử lại',
  confirm: 'Xác nhận',
  errorAuthen: 'Lỗi đăng nhập. Vui lòng kiểm tra lại email hoặc mật khẩu!',

  category: 'Danh mục',
  language: {
    vi: 'Tiếng Việt',
    ja: 'Japanese',
    ko: '한국',
    en: 'English',
  },
  /** Booking setting */
  storeSetting: 'Cài đặt cho Nhà hàng',
  regularHoliday: 'Ngày nghỉ',
  am: 'Trưa',
  pm: 'Tối',
  bookingSetting: 'Cài đặt cho Booking',
  bookingToday: 'Thời gian cho phép Booking (Giờ)',
  before: 'Trước',
  bookingRange: 'Giới hạn số lượng booking (Người) (*)',
  errorDay: 'Vui lòng nhập ngày mở cửa',
  placeholderMinRange: 'Tối thiểu: 1',
  placeholderMaxRange: 'Tối đa: 100',
  courseEmpty: 'Hiện chưa có set món ăn nào.',
  popular: 'Phổ biến',
  errorRequiredLogin:
    'Bạn chưa đăng kí tài khoản cho Booking. Vui lòng liện hệ với bộ phận chăm sóc khách hàng của Capichi',
  rangeBook: {
    errorRequired: 'Số lượng người không được bé hơn 1',
    errorMinBook: 'Số lượng người tối thiểu phải bé hơn số lượng người tối đa',
    errorMaxBook: 'Số lượng người tối đa phải lớn hơn số lượng người tối thiểu',
    required: 'Vui lòng nhập đầy đủ dữ liệu',
  },
  resetSetting: 'Xoá cài đặt',
  notBookingDay: 'Ngày không cho phép Booking',
  nonReservableSetting: 'Cài đặt cho ngày và giờ không được booking',
  reservableAll: 'Cả ngày',
  reservableSelect: 'Chọn giờ',
  cannotBookingAllDay: 'Không thể booking cả ngày',
  bookingCourseList: 'Danh sách Set món ăn',
  bookingCourseDescription: 'Hiển thị danh sách các Set món ăn của nhà hàng',
  bookingCourse: {
    id: 'ID set đồ ăn',
    info: 'Thông tin set đồ ăn',
    detail: 'Chi tiết',
    action: 'Hành động',
    create: 'Tạo set món ăn',
    delete: 'Xoá Set món ăn',
    confirmDelete: 'Bạn có chắc muốn xoá Set món ăn này?',
  },
  createCourse: 'Tạo Set món ăn',
  editCourse: 'Chỉnh sửa Set món ăn',
  nameCourse: 'Tên Set món ăn',
  priceCourse: 'Giá Set món ăn',
  courseRange: 'Giới hạn số lượng người ăn',
  imageCourse: 'Ảnh Set món ăn',
  courseError: {
    validatePrice: 'Số tiền cho Set món ăn ít nhất 1 kí tự, tối đa 15 kí tự.',
    requiredName: 'Tên cho Set món ăn ít nhất 1 kí tự, tối đa 200 kí tự.',
    requiredImage: 'Bạn cần phải thêm ảnh cho Set món ăn.',
  },
  bookingList: {
    title: 'Danh sách Booking',
    description: 'Hiển thị danh sách Booking',
    id: 'Id booking',
    user: 'Chi tiết Khách hàng',
    people: 'Số lượng Khách hàng',
    date: 'Ngày đặt bàn',
    time: 'Giờ đặt bàn',
    note: 'Ghi chú',
    course: 'Set đồ ăn',
    action: 'Hành động',
    empty: 'Hiện tại đang chưa có Booking nào tại nhà hàng của bạn.',
    status: 'Trạng thái',
  },
  bookingAction: {
    confirm: 'Xác nhận',
    cancel: 'Huỷ',
    doneConfirm: 'Đã xác nhận',
    doneCancel: 'Đã huỷ',
    dialogConfirm: {
      label: 'Xác nhận Booking',
      title: 'Bạn có chắc chắn muốn xác nhận Booking?',
    },
    dialogCancel: {
      label: 'Huỷ Booking',
      title: 'Bạn có chắc chắn muốn huỷ Booking?',
    },
  },
  notificationBooking: {
    label: 'Danh sách thông báo',
    description: 'Hiển thị danh sách thông báo',
    id: 'Mã thông báo',
    title: 'Tiêu đề',
    idBooking: 'Mã Booking',
    time: 'Thời gian',
    empty: 'Hiện tại chưa có thông báo nào.',
  },
  next: 'Tiếp tục',
  emptyTime: 'Vui lòng cài đặt thời gian mở cửa của nhà hàng',
  day: {
    mon: 'Thứ 2',
    tue: 'Thứ 3',
    wed: 'Thứ 4',
    thu: 'Thứ 5',
    fri: 'Thứ 6',
    sat: 'Thứ 7',
    sun: 'Chủ nhật',
  },
  statusBooking: {
    pending: 'Đang xử lý',
    cancel: 'Đã huỷ',
    confirm: 'Đã xác nhận',
    expired: 'Hết hạn',
    cancelByOwner: 'Nhà hàng huỷ',
    cancelByCustomer: 'Khách hàng huỷ',
  },
  customer: {
    name: 'Tên khách hàng',
    email: 'Email khách hàng',
    info: 'Thông tin khách hàng',
    phone: 'Số điện thoại',
  },
  bookingInfo: 'Thông tin đặt bàn',
  timeBooking: 'Thời gian đặt',
  numberBooking: 'Số lượng người',
  noteEdit: 'Lý do chỉnh sửa',
  course: 'Set món ăn',
  choose_time: 'Chọn giờ',
  booking: {
    time_required: 'Vui lòng chọn thời gian đặt bàn.',
    reason_required: 'Vui lòng nhập lý do chỉnh sửa.',
    success: 'Bạn đã cập nhật thông tin đặt bàn thành công.',
  },
  noteCancel: 'Lý do huỷ Booking',
  cancelBooking: 'Huỷ Booking',
  validateNoteCancel: 'Vui lòng nhập lý do huỷ Booking',
  editBooking: 'Chỉnh sửa Booking',
}

import _, { isNull, isUndefined } from 'lodash'
import request from './request'
import { apiBookingUpdate, listBookingApi } from './config'
import { getMessageErrorFormServer } from '../commons'

export const getListBooking = async ({ authen, page, limit = 10, cancel }) => {
  try {
    const result = await request(authen, cancel).get(
      `${listBookingApi}?page=${page}&limit=${limit}`
    )
    const { data, status } = result
    if (status === 200)
      return {
        isSuccess: true,
        data: data && Array.isArray(data.data) ? data.data : [],
        maxCount: data && data.paging && data.paging.total,
      }
    return {
      isSuccess: false,
      message: 'a',
    }
  } catch (e) {
    return {
      isSuccess: false,
      message: getMessageErrorFormServer(e),
    }
  }
}

export const updateBooking = async ({ authenToken, statusBooking, id }) => {
  try {
    const formData = new FormData()
    if (statusBooking) formData.append('booking[status]', statusBooking)
    const response = await request(authenToken).put(`${listBookingApi}/${id}`, formData)
    const { data, status } = response
    if (status === 200)
      return {
        isSuccess: true,
        data: data.data ? data.data : {},
      }
    return {
      isSuccess: false,
      message: 'error',
    }
  } catch (e) {
    return {
      isSuccess: false,
      message: getMessageErrorFormServer(e),
    }
  }
}

/**
 * Cancel booking Api
 * @param {*} param0
 */
export const cancelBookingApi = async ({ authenToken, noteCancel, bookingId }) => {
  console.log('cancelBookingApi -> bookingId', bookingId)
  try {
    const formData = new FormData()
    formData.append('booking[restaurant_note]', noteCancel)
    formData.append('booking[status]', 'cancel')
    const response = await request(authenToken).put(`${listBookingApi}/${bookingId}`, formData)
    const { data, status } = response
    if (status === 200)
      return {
        isSuccess: true,
        data: data.data ? data.data : {},
      }
    return {
      isSuccess: false,
      message: 'error',
    }
  } catch (e) {
    console.log(e.response)
    return {
      isSuccess: false,
      message: getMessageErrorFormServer(e),
    }
  }
}

export const getDetailBooking = async ({ authen, bookingId, cancel }) => {
  try {
    const result = await request(authen, cancel).get(`${listBookingApi}/${bookingId}`)
    const { data, status } = result
    if (status === 200)
      return {
        isSuccess: true,
        data: data.data,
      }
    return {
      isSuccess: false,
      message: 'a',
    }
  } catch (e) {
    return {
      isSuccess: false,
      message: getMessageErrorFormServer(e),
    }
  }
}

/**
 * func update booking
 * @param {*} param0
 */
export const updateBookingUser = async ({ authen, id, newCourse, oldCourse, ...props }) => {
  try {
    const formdata = new FormData()
    const key = _.keys(props)
    for (let index = 0; index < key.length; index += 1) {
      const element = key[index]

      if (!isUndefined(props[element]) && !isNull(props[element])) {
        formdata.append(`booking[${element}]`, props[element])
      }
    }
    if (!isUndefined(newCourse) && !isNull(newCourse)) {
      formdata.append(`booking[booking_courses_attributes][${0}][course_id]`, newCourse.id)
    }

    if (!isUndefined(oldCourse) && !isNull(oldCourse)) {
      formdata.append(`booking[booking_courses_attributes][0][_destroy]`, true)
    }

    // if (!isUndefined(oldCourse) && !isNull(oldCourse)) {
    //   formdata.append(`booking[booking_courses_attributes][0][course_id]`, oldCourse.id)
    //   formdata.append(`booking[booking_courses_attributes][0][id]`, oldCourse.id)
    //   formdata.append(`booking[booking_courses_attributes][0][_destroy]`, true)
    // }
    const result = await request(authen).put(apiBookingUpdate(id), formdata)
    return {
      success: true,
      data: result.data?.data,
    }
  } catch (error) {
    return {
      success: false,
      message: getMessageErrorFormServer(error),
    }
  }
}

/* eslint-disable no-useless-escape */
/* eslint-disable react/no-array-index-key */
import React, { Component, Children } from 'react'
// @material-ui/core components
// core components
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Grid, TextField, Button, Checkbox } from '@material-ui/core'
import PlaylistAdd from '@material-ui/icons/PlaylistAdd'
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck'
import CircularProgress from '@material-ui/core/CircularProgress'
// import Calendar from 'react-calendar'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import _ from 'lodash'
import ReactInputMask from 'react-input-mask'
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import MessageResult from '../../components/MessageResult'
import Card from '../../components/Card/Card'
import CardHeader from '../../components/Card/CardHeader'
import CardBody from '../../components/Card/CardBody'
import bgImage from '../../assets/img/restaurant-banner.jpg'
import '../../assets/css/Profile/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import ReservablePopup from './ReservablePopup'
import { YYYY_MM_DD, DD_MM_YYYY, AUTHEN_TOKEN, dayList } from '../../constants/define'
import { getProfileApi, updateProfile } from '../../api'

const localizer = momentLocalizer(moment)
const HelperText = ({ error }) => {
  if (typeof error === 'string' && error.length > 0) {
    const arr = error.split('/')
    return (
      <>
        {arr.map((i, index) => {
          return (
            <span
              key={`${i}-${index}`}
              className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
              style={{
                marginTop: '10px',
                marginLeft: 0,
                color: 'red',
                marginBottom: '-15px',
              }}
            >
              {i}
            </span>
          )
        })}
      </>
    )
  }
  return null
}
HelperText.propTypes = {
  error: PropTypes.any,
}

const errorTitle = {
  time: {
    min: 'time.min',
    max: 'time.max',
    open: 'time.open',
    close: 'time.close',
  },
}

const resultTitle = {
  error: 'result.error',
  success: 'result.success',
}

const CURRENT_DATE = moment().subtract(1, 'day')

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataProfile: null,
      time: [
        {
          start: '',
          end: '',
          errorStart: '',
          errorEnd: '',
          regularHoliday: [],
        },
      ],
      notBooking: [],
      message: '',
      todayTime: null,
      minBook: null,
      maxBook: null,
      nonReservable: null,
      errorMinBook: '',
      errorMaxBook: '',
      eventCalendar: [],
      isLoadingProfile: true,
      // regularHoliday: [
      //   { mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false },
      // ],
    }
    this.popup = React.createRef()
    this.header = React.createRef()
  }

  componentDidMount = () => {
    this.onGetData()
  }

  onGetData = async () => {
    const authenToken = localStorage.getItem(AUTHEN_TOKEN)
    this.setState({
      isLoadingProfile: true,
    })
    try {
      const result = await getProfileApi(authenToken)
      if (result.status === 'success') {
        const { data } = result
        const keyCsvTime = Object.keys(data && data.open_time ? data.open_time : null)
        const timeFormat = keyCsvTime
          ? keyCsvTime.map(value => {
              const arrti = data.open_time[value].time
              return {
                start: `${arrti[0] || ''}`.trim(),
                end: `${arrti[1] || ''}`.trim(),
                errorStart: '',
                errorEnd: '',
                regularHoliday: data.open_time[value].day,
              }
            })
          : []
        const reservableEvent = this.convertEvent(data.non_reservable_datetime || {})

        let arrayBookingDay = data && data.not_booking_day ? data.not_booking_day : null
        if (arrayBookingDay && typeof arrayBookingDay === 'string') {
          arrayBookingDay = JSON.parse(
            data.not_booking_day
              .replace(/\'/g, '"')
              .replace(/^"/, "'")
              .replace(/"$/, "'")
          )
        }

        this.setState(preState => {
          return {
            dataProfile: data,
            time: keyCsvTime && keyCsvTime.length > 0 ? timeFormat : preState.time,
            eventCalendar: reservableEvent,
            nonReservable: this.pickObject(data.non_reservable_datetime || {}),
            todayTime: data && data.book_before ? data.book_before : null,
            minBook: data && data.min_diner ? data.min_diner : null,
            maxBook: data && data.max_diner ? data.max_diner : null,
            notBooking: arrayBookingDay || [],
            isLoadingProfile: false,
          }
        })
      } else {
        this.setState({ isLoadingProfile: false, message: 'a' })
      }
    } catch (e) {
      this.setState({ isLoadingProfile: false, message: 'a' })
    }
  }

  /**
   * Hàm xử lý filter object
   */
  pickObject = object => {
    if (object) {
      const pickObject = _.pickBy(
        object,
        value => value.isAll || (Array.isArray(value.times) && value.times.length > 0)
      )
      return pickObject
    }
    return {}
  }

  /**
   * Hàm xử lý convert event
   */
  convertEvent = nonReservableTimeArray => {
    const reservableKey = Object.keys(this.pickObject(nonReservableTimeArray))
    if (Array.isArray(reservableKey)) {
      const reservableEvent = reservableKey.map((value, key) => {
        const dateArray = value.split('-')
        return {
          id: key,
          isAll: nonReservableTimeArray[value].isAll,
          start: new Date(Number(dateArray[2]), Number(dateArray[1]) - 1, Number(dateArray[0])),
          end: new Date(Number(dateArray[2]), Number(dateArray[1]) - 1, Number(dateArray[0])),
        }
      })
      return reservableEvent
    }
    return []
  }

  /**
   * Hàm customize day of calendar
   */
  ColoredDateCellWrapper = ({ children, value }) => {
    return React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: value < CURRENT_DATE && '#ccc',
      },
    })
  }

  /**
   * Hàm xử lý khi click ra ngoài End time
   */
  onBlurEndTime = index => {
    const { time } = this.state
    const value = time[index]
    if (value.end && /_/.test(value.end) === false) {
      const check =
        time.filter((i, inx) => {
          if (inx === index) return false
          return (
            (/_/.test(i.start) === false &&
              value.end >= i.start &&
              /_/.test(i.end) === false &&
              value.end <= i.end) ||
            (value.start &&
              /_/.test(value.start) === false &&
              /_/.test(i.start) === false &&
              value.end >= i.end &&
              /_/.test(i.end) === false &&
              value.start <= i.start)
          )
        }).length > 0
      if (check) {
        value.errorEnd = errorTitle.time.max
        time[index] = value
        this.setState({ time })
        return
      }
    }
    if (!value.end || value.end.length <= 0 || /_/.test(value.end)) {
      value.errorEnd = errorTitle.time.close
      time[index] = value
      this.setState({ time })
      return
    }

    if (value.end && /_/.test(value.end) === false) {
      if (value.start >= value.end) {
        value.errorEnd = errorTitle.time.min
        time[index] = value
        this.setState({ time })
      }
    }
  }

  /**
   * Hàm xử lý khi thay đổi end time
   */
  onChangeEndTime = (e, index) => {
    const { value } = e.nativeEvent.target
    if (Number(value[1]) > 3 && Number(value[0]) >= 2) return
    if (Number(value[0]) >= 3) return
    if (Number(value[0]) === 2 && Number(value[1]) > 3) {
      return
    }

    if (Number(value[3] > 5)) return

    const { time } = this.state
    const newData = time[index]
    newData.end = value
    newData.errorEnd = /_/.test(value) ? newData.errorEnd : ''
    time[index] = newData

    this.setState({ time })
  }

  /**
   * Hàm xử lý khi thêm mới Open time
   */
  onAddTime = () => {
    this.setState(state => ({
      time: state.time.concat({
        start: '',
        end: '',
        errorStart: '',
        errorEnd: '',
        regularHoliday: [],
      }),
    }))
  }

  customEvent = ({ event }) => {
    const { classes } = this.props
    return (
      <div
        className={classes.eventStyle}
        style={{ backgroundColor: event.isAll ? '#5385AA' : '#f08e59' }}
      >
        {event.isAll ? <PlaylistAddCheck color="white" /> : <PlaylistAdd color="white" />}
      </div>
    )
  }

  /**
   * Hàm xử lý khi xoá Open time
   */
  onDeleteTime = index => () => {
    const { time } = this.state
    time.splice(index, 1)
    this.setState({ time })
  }

  /**
   * Hàm xử lý khi click ra ngoài Start time
   */
  onBlurStartTime = index => {
    const { time } = this.state
    const value = time[index]

    if (value.start && /_/.test(value.start) === false) {
      const check =
        time.filter((i, inx) => {
          if (inx === index) return false
          return (
            (/_/.test(i.end) === false &&
              value.start <= i.end &&
              /_/.test(i.start) === false &&
              value.start >= i.start) ||
            (value.start &&
              /_/.test(value.end) === false &&
              /_/.test(i.start) === false &&
              value.end >= i.end &&
              /_/.test(i.end) === false &&
              value.start <= i.start)
          )
        }).length > 0
      if (check) {
        value.errorStart = errorTitle.time.max
        time[index] = value
        this.setState({ time })
        return
      }
    }

    if (!value.start || value.start.length <= 0 || /_/.test(value.start)) {
      value.errorStart = errorTitle.time.open
      time[index] = value
      this.setState({ time })
      return
    }

    if (value.end && !/_/.test(value.end)) {
      if (value.start >= value.end) {
        value.errorEnd = errorTitle.time.min
        time[index] = value
        this.setState({ time })
      }
    }
  }

  /**
   * Hàm xử lý khi thay đổi Start time
   */
  onChangeStartTime = (e, index) => {
    const { value } = e.nativeEvent.target
    if (Number(value[1]) > 3 && Number(value[0]) >= 2) return
    if (Number(value[0]) >= 3) return
    if (Number(value[0]) === 2 && Number(value[1]) > 3) {
      return
    }

    if (Number(value[3] > 5)) return

    const { time } = this.state
    const newData = time[index]
    newData.start = value
    newData.errorStart = /_/.test(value) ? newData.errorStart : ''
    time[index] = newData

    this.setState({ time })
  }

  /**
   * Hàm render Days
   */
  renderRegularHoliday = ({ holidays, index, isAllow }) => {
    const { classes, language } = this.props

    const renderHoliday = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']

    return (
      <div style={{ flexDirection: 'row', alignItems: 'center' }}>
        {renderHoliday.map(value => {
          const check = _.find(holidays, valueHoliday => {
            return valueHoliday === value
          })

          return (
            <>
              <Checkbox
                checked={!!check}
                onChange={() =>
                  isAllow
                    ? this.onCheckNotAllowBooking(value, !!check)
                    : this.onCheckRegularHoliday(value, index, !!check, holidays)
                }
              />
              <span className={classes.dayLabel}>{language.t(dayList[value])}</span>
            </>
          )
        })}
      </div>
    )
  }

  /**
   * Hàm check not Booking days
   */
  onCheckNotAllowBooking = (value, check) => {
    const { notBooking } = this.state

    const holidayArray = [...notBooking]

    if (!check) {
      holidayArray.push(value)
    } else {
      _.remove(holidayArray, valueRemove => valueRemove === value)
    }

    this.setState({
      notBooking: holidayArray,
    })
  }

  /**
   * Hàm checkbox Holiday
   */
  onCheckRegularHoliday = (valueDay, index, check, holidays) => {
    const holidayArray = [...holidays]
    if (!check) {
      holidayArray.push(valueDay)
    } else {
      _.remove(holidayArray, valueRemove => valueRemove === valueDay)
    }

    const { time } = this.state
    const timeArray = [...time]
    timeArray.splice(index, 1, {
      ...timeArray[index],
      regularHoliday: holidayArray,
    })

    this.setState({
      time: timeArray,
    })
  }

  /**
   * Ham check booking today
   */

  onChangeBookingToday = event => {
    const { value } = event.nativeEvent.target
    this.setState({
      todayTime: value.replace(/[\D]/g, '') > 168 ? 168 : value.replace(/[\D]/g, ''),
    })
  }

  onChangeMinBook = event => {
    const { value } = event.nativeEvent.target
    this.setState({
      minBook: value.replace(/[\D]/g, '') > 100 ? 100 : value.replace(/[\D]/g, ''),
      errorMinBook: '',
    })
  }

  onChangeMaxBook = event => {
    const { value } = event.nativeEvent.target
    this.setState({
      maxBook: value.replace(/[\D]/g, '') > 100 ? 100 : value.replace(/[\D]/g, ''),
      errorMaxBook: '',
    })
  }

  onBlurMinBook = () => {
    const { minBook, maxBook } = this.state
    const convertMinBook = Number(minBook)
    const convertMaxBook = Number(maxBook)
    if (convertMinBook < 1) {
      this.setState({
        errorMinBook: 'rangeBook.errorRequired',
      })
    } else if (convertMinBook >= convertMaxBook) {
      this.setState({
        errorMinBook: 'rangeBook.errorMinBook',
      })
    } else if (convertMaxBook > convertMinBook && convertMaxBook >= 1 && convertMinBook >= 1) {
      this.setState({
        errorMaxBook: '',
        errorMinBook: '',
      })
    }
  }

  onBlueMaxBook = () => {
    const { minBook, maxBook } = this.state
    const convertMinBook = Number(minBook)
    const convertMaxBook = Number(maxBook)
    if (maxBook < 1) {
      this.setState({
        errorMaxBook: 'rangeBook.errorRequired',
      })
    } else if (convertMaxBook <= convertMinBook) {
      this.setState({
        errorMaxBook: 'rangeBook.errorMaxBook',
      })
    } else if (convertMaxBook > convertMinBook && convertMaxBook >= 1 && convertMinBook >= 1) {
      this.setState({
        errorMaxBook: '',
        errorMinBook: '',
      })
    }
  }

  /**
   * Hàm xử lý khi click vào Calendar
   */
  handleSelectSlot = event => {
    const { slots } = event

    const { time, nonReservable } = this.state
    const nonReservableKeys = Object.keys(nonReservable)

    const currentDate = moment().format(YYYY_MM_DD)
    const clickDate = moment(slots[0]).format(YYYY_MM_DD)
    const isSelected = _.find(nonReservableKeys, value => {
      return moment(value, DD_MM_YYYY).format(YYYY_MM_DD) === clickDate
    })

    const isDayBefore = moment(clickDate).isBefore(currentDate)
    if (!isDayBefore) {
      this.popup.current.show(
        clickDate,
        time,
        isSelected ? nonReservable[isSelected] : null,
        this.handleChooseTime
      )
    }
  }

  /**
   * Hàm xử lý thay đổi time trên Popup
   */
  handleChooseTime = (reservableTime, currentDate) => {
    if (reservableTime) {
      const { nonReservable } = this.state
      const convertDate = moment(currentDate, YYYY_MM_DD).format(DD_MM_YYYY)

      const convertReservable = { ...nonReservable, [convertDate]: reservableTime }
      const events = this.convertEvent(convertReservable)

      this.setState({
        nonReservable: this.pickObject(convertReservable),
        eventCalendar: events,
      })
    }
  }

  /**
   * Hàm xử lý lưu data
   */
  onSaveData = () => {
    const error = {}
    let check = true
    const { errorMinBook, errorMaxBook, time, minBook, maxBook } = this.state

    const newTime = time.reduce((a, b) => {
      const newB = b
      if ((b.errorStart && b.errorStart.length > 0) || (b.errorEnd && b.errorEnd.length > 0))
        check = false
      if (!b.end || b.end.length <= 0) {
        newB.errorEnd = errorTitle.time.close
        check = false
      }
      if (!b.start || b.start.length <= 0) {
        newB.errorStart = errorTitle.time.open
        check = false
      }
      return [...a, newB]
    }, [])

    if (errorMinBook.length > 0 || errorMaxBook.length > 0) {
      return
    }

    if (minBook === null || maxBook === null) {
      this.setState({
        errorMinBook: minBook === null ? 'rangeBook.required' : '',
        errorMaxBook: maxBook === null ? 'rangeBook.required' : '',
      })
      return
    }

    if (!check) {
      this.setState({ ...error, time: newTime })
    } else {
      const { notBooking, todayTime, nonReservable } = this.state
      let openTime = {
        am: {
          time: [newTime[0].start, newTime[0].end],
          day: newTime[0].regularHoliday,
        },
      }
      if (newTime.length === 2) {
        openTime = {
          ...openTime,
          pm: {
            time: [newTime[1].start, newTime[1].end],
            day: newTime[1].regularHoliday,
          },
        }
      }
      const keyNonReservable = Object.keys(nonReservable)
      const params = {
        not_booking_day: JSON.stringify(notBooking),
        book_before: Number(todayTime),
        min_diner: Number(minBook),
        max_diner: Number(maxBook),
        non_reservable_datetime: keyNonReservable.length > 0 ? nonReservable : null,
        open_time: openTime,
      }

      updateProfile({ authen: localStorage.getItem(AUTHEN_TOKEN), ...params }).then(result => {
        if (result.isSuccess) {
          this.setState({ message: resultTitle.success })
        } else {
          this.setState({ message: resultTitle.error })
        }
        setTimeout(() => {
          this.setState({ message: '' })
        }, 5000)
      })
      this.header.current.scrollIntoView()
    }
  }

  render() {
    const { classes, language } = this.props
    const {
      time,
      todayTime,
      minBook,
      maxBook,
      errorMinBook,
      errorMaxBook,
      notBooking,
      eventCalendar,
      message,
      dataProfile,
      isLoadingProfile,
    } = this.state
    const headerClass = classnames('profile-header', classes.header)
    const headerContentClass = classnames('profile-header--content', classes.headerContent)
    const isDisableAddBtn = time.length === 2

    return (
      <div>
        <GridContainer id="booking-setting">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader id="user-profile-header" color="primary" className={headerClass}>
                <div className={headerContentClass} ref={this.header} />
              </CardHeader>
              <MessageResult
                message={message && language.t(message)}
                language={language}
                isErrorGetData={!dataProfile}
              />
              {dataProfile ? (
                <CardBody>
                  {isLoadingProfile ? (
                    <div className={classes.contentLoader}>
                      <CircularProgress
                        size={40}
                        color="inherit"
                        style={{ color: '#000', marginBottom: '20px' }}
                      />
                    </div>
                  ) : (
                    <>
                      {/* Open time setting */}
                      <span className={classes.label}>{language.t('storeSetting')}</span>
                      <Grid container className={classes.gridContainer}>
                        <Grid item xs={12} style={{ marginTop: '-15px' }}>
                          {time.map((ti, index) => {
                            return (
                              <Grid key={index} container style={{ marginBottom: '20px' }}>
                                <Grid item xs={12} className={classes.gridItem}>
                                  <span className={classes.gridLabel}>
                                    {language.t('openTime')}
                                  </span>
                                  <span
                                    style={{ marginLeft: '10px' }}
                                    className={classes.gridLabel}
                                  >{`(${
                                    index === 0 ? language.t('am') : language.t('pm')
                                  }) (*)`}</span>
                                </Grid>
                                <Grid container>
                                  <Grid item sm={2} md={2} style={{ marginTop: '15px' }}>
                                    <ReactInputMask
                                      mask="99:99"
                                      onChange={e => this.onChangeStartTime(e, index)}
                                      onBlur={() => this.onBlurStartTime(index)}
                                      value={ti.start}
                                      style={{ width: '100px' }}
                                    >
                                      {() => (
                                        <TextField
                                          error={
                                            typeof ti.errorStart === 'string' &&
                                            ti.errorStart.length > 0
                                          }
                                          variant="outlined"
                                          placeholder="hh/mm"
                                          style={{ paddingTop: 0, marginTop: 0, width: '100%' }}
                                          FormHelperTextProps={{
                                            style: { marginLeft: 0 },
                                          }}
                                          size="small"
                                        />
                                      )}
                                    </ReactInputMask>
                                    <HelperText
                                      error={ti.errorStart && language.t(ti.errorStart)}
                                    />
                                  </Grid>
                                  <Grid item sm={1} md={1} style={{ marginTop: '-10px' }}>
                                    <p
                                      style={{
                                        textAlign: 'center',
                                        fontSize: '30px',
                                      }}
                                    >
                                      ~
                                    </p>
                                  </Grid>
                                  <Grid item sm={2} md={2} style={{ marginTop: '15px' }}>
                                    <ReactInputMask
                                      mask="99:99"
                                      onChange={e => this.onChangeEndTime(e, index)}
                                      onBlur={() => this.onBlurEndTime(index)}
                                      value={ti.end}
                                      style={{ width: '100px' }}
                                    >
                                      {() => (
                                        <TextField
                                          error={
                                            typeof ti.errorEnd === 'string' &&
                                            ti.errorEnd.length > 0
                                          }
                                          placeholder="hh/mm"
                                          variant="outlined"
                                          style={{ paddingTop: 0, marginTop: 0, width: '100%' }}
                                          FormHelperTextProps={{
                                            style: { marginLeft: 0 },
                                          }}
                                          size="small"
                                        />
                                      )}
                                    </ReactInputMask>
                                    <HelperText error={ti.errorEnd && language.t(ti.errorEnd)} />
                                  </Grid>
                                  {index !== 0 ? (
                                    <GridItem sm={2} md={3} style={{ marginTop: '15px' }}>
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={this.onDeleteTime(index)}
                                      >
                                        {language.t('delete')}
                                      </Button>
                                    </GridItem>
                                  ) : null}
                                </Grid>
                                <Grid container>
                                  {/* <Grid item xs={12}>
                              <span className={classes.gridLabel}>
                                {language.t('regularHoliday')}
                              </span>
                            </Grid> */}
                                  <Grid item xs={12}>
                                    {this.renderRegularHoliday({
                                      holidays: ti.regularHoliday,
                                      index,
                                    })}
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          })}
                          <Grid item md={6}>
                            <Button
                              variant="contained"
                              color="inherit"
                              style={{
                                backgroundColor: isDisableAddBtn ? '#ccc' : '#F7941D',
                                color: 'white',
                              }}
                              onClick={isDisableAddBtn ? () => {} : this.onAddTime}
                            >
                              {language.t('addNew')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Booking setting */}
                      <span
                        style={{ marginTop: '60px', display: 'block' }}
                        className={classes.label}
                      >
                        {language.t('bookingSetting')}
                      </span>
                      <Grid container>
                        <Grid item xs={12} style={{ margin: '20px 0 10px 0' }}>
                          <span className={classes.gridLabel}>{language.t('bookingToday')}</span>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}
                        >
                          <span style={{ marginRight: '15px' }}>{language.t('before')}</span>
                          <TextField
                            InputProps={{ value: todayTime, onChange: this.onChangeBookingToday }}
                            id="bookingToday"
                            variant="outlined"
                            className={classes.inputNumberToday}
                            size="small"
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} style={{ margin: '20px 0 10px 0' }}>
                          <span className={classes.gridLabel}>{language.t('bookingRange')}</span>
                        </Grid>
                        <Grid item xs={12} style={{ flexDirection: 'row', display: 'flex' }}>
                          <div className={classes.contentHelper}>
                            <TextField
                              error={typeof errorMinBook === 'string' && errorMinBook.length > 0}
                              InputProps={{ value: minBook, onChange: this.onChangeMinBook }}
                              id="minBook"
                              variant="outlined"
                              className={classes.inputNumber}
                              size="small"
                              placeholder={language.t('placeholderMinRange')}
                              onBlur={
                                minBook !== null && maxBook !== null ? this.onBlurMinBook : null
                              }
                            />
                            <HelperText error={errorMinBook && language.t(errorMinBook)} />
                          </div>

                          <p
                            style={{
                              textAlign: 'center',
                              fontSize: '30px',
                              margin: '5px 15px 0 15px',
                            }}
                          >
                            ~
                          </p>
                          <div className={classes.contentHelper}>
                            <TextField
                              error={typeof errorMaxBook === 'string' && errorMaxBook.length > 0}
                              InputProps={{ value: maxBook, onChange: this.onChangeMaxBook }}
                              id="maxBook"
                              variant="outlined"
                              placeholder={language.t('placeholderMaxRange')}
                              className={classes.inputNumber}
                              size="small"
                              onBlur={
                                minBook !== null && maxBook !== null ? this.onBlueMaxBook : null
                              }
                            />
                            <HelperText error={errorMaxBook && language.t(errorMaxBook)} />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} style={{ margin: '20px 0 10px 0' }}>
                          <span className={classes.gridLabel}>{language.t('notBookingDay')}</span>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}
                        >
                          {this.renderRegularHoliday({ holidays: notBooking, isAllow: true })}
                        </Grid>
                      </Grid>

                      {/* Calendar Setting */}
                      <span
                        style={{ marginTop: '60px', display: 'block', marginBottom: '35px' }}
                        className={classes.label}
                      >
                        {language.t('nonReservableSetting')}
                      </span>
                      <div style={{ padding: '0 100px' }}>
                        <Calendar
                          selectable
                          localizer={localizer}
                          events={eventCalendar}
                          startAccessor="start"
                          endAccessor="end"
                          style={{ height: 500 }}
                          onSelectSlot={this.handleSelectSlot}
                          views={{ month: true }}
                          // onSelectEvent={this.handleSelectSlot}
                          // dayPropGetter={customDayPropGetter}
                          components={{
                            // you have to pass your custom wrapper here
                            // so that it actually gets used
                            dateCellWrapper: this.ColoredDateCellWrapper,
                            event: this.customEvent,
                          }}
                        />
                      </div>

                      <Grid container className={classes.gridContainer}>
                        <GridItem xs={12} sm={9} md={9}>
                          <Button
                            variant="contained"
                            color="inherit"
                            style={{
                              backgroundColor: '#F7941D',
                              color: 'white',
                            }}
                            // disabled={disableSave}
                            className={classes.buttonfilter}
                            onClick={this.onSaveData}
                          >
                            {language.t('save')}
                          </Button>
                        </GridItem>
                      </Grid>
                    </>
                  )}
                </CardBody>
              ) : null}
            </Card>
          </GridItem>
        </GridContainer>
        <ReservablePopup ref={this.popup} language={language} />
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    language: state.languageReducer.language,
  }
}

const styles = {
  contentHelper: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
  },
  dayLabel: {
    marginRight: '15px',
    textTransform: 'capitalize',
  },
  gridLabel: {
    fontSize: '18px',
    fontWeight: '500',
  },
  gridItem: {
    margin: '5px 0',
  },
  label: {
    fontWeight: '500',
    textTransform: 'uppercase',
    fontSize: '28px',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  eventStyle: {
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  infoContainer: {
    width: '100%',
    marginTop: '20px',
  },
  inputNumberToday: {
    width: '80px',
  },
  infoText: {
    maxWidth: '100%',
    padding: '0px 20px',
    textAlign: 'justify',
  },
  header: {
    backgroundImage: `url(${bgImage}) !important`,
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(79, 76, 73,.4) !important',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 center',
  },
  headerContent: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: '3px',
    padding: '20px 30px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerImage: {
    width: '120px',
    height: '120px',
    objectFit: 'contain',
    marginRight: '30px',
  },
  headerText: {
    fontSize: '25px',
    color: '#fff',
  },
  imageLoader: {
    width: '120px',
    height: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#f2f2f2',
    marginRight: '30px',
  },
  contentLoader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridContainer: { marginTop: '30px', marginBottom: '25px' },
  input: {
    width: '100%',
    // padding: '12px',
  },
  inputNumber: {
    width: '100%',
  },
  textAvatar: {
    backgroundColor: '#000000',
    opacity: 0.8,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,
    '&:hover': {
      zIndex: 10,
    },
  },
  avatar: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    '&:hover': {
      zIndex: -1,
    },
  },
}

Profile.propTypes = {
  language: PropTypes.any,
  classes: PropTypes.any,
}
export default connect(mapStateToProps)(withStyles(styles)(Profile))

import moment from 'moment'
import { formatTime } from '../constants/define'

export const generateTimeRange = (startTime, endTime) => {
  // const splitTime = range.split('-')
  const arrayTime = []

  const remainderStart = 15 - (moment(startTime, formatTime).minute() % 15)

  const remainderEnd = moment(endTime, formatTime).minute() % 15
  let timeConvertStart = moment(startTime, formatTime)
    .add(remainderStart, 'minutes')
    .format(formatTime)
  const timeConvertEnd = moment(endTime, formatTime)
    .subtract(remainderEnd, 'minutes')
    .format(formatTime)

  while (moment(timeConvertStart, formatTime) <= moment(timeConvertEnd, formatTime)) {
    arrayTime.push(moment(timeConvertStart, formatTime).format(formatTime))
    timeConvertStart = moment(timeConvertStart, formatTime)
      .add(15, 'minutes')
      .format(formatTime)
  }

  return arrayTime
}

export const testTime = () => {}

import React, { Component } from 'react'
// @material-ui/core components
// import InputLabel from '@material-ui/core/InputLabel'
import PropTypes from 'prop-types'
// core components
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import { Alert } from '@material-ui/lab'

// import classnames from 'classnames'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import Compress from 'compress.js'
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import InputOutline from '../../components/CustomInput/InputOutline'
import Card from '../../components/Card/Card'
import CardHeader from '../../components/Card/CardHeader'
import CardBody from '../../components/Card/CardBody'
import bgImage from '../../assets/img/restaurant-banner.jpg'
import '../../assets/css/Profile/styles.css'
import { formatMoney } from '../../commons/momey'
import { mainColor } from '../../constants/style.js'
import { AUTHEN_TOKEN, languageList, regexEmoji, maxLengthPrice } from '../../constants/define'
import { createCourse, getDetailCourse, editCourse } from '../../api/course'
import { isEmptyContent } from '../../commons'

const compress = new Compress()
class NewBookingCourse extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isLoadingCreate: false,
      name: '',
      nameVi: '',
      nameJa: '',
      nameKo: '',
      price: 0,
      image: undefined,
      imageUpload: undefined,
      error: undefined,
      isGetData: true,
      validate: {},
      minRange: '',
      maxRange: '',
      note: '',
      noteVi: '',
      noteJa: '',
      noteKo: '',
      idLanguageVi: '',
      idLanguageJa: '',
      idLanguageKo: '',
      errorMaxBook: '',
      errorMinBook: '',
    }
  }

  componentDidMount = () => {
    this.onFetchData()
  }

  onFetchData = async () => {
    const { match } = this.props
    const { id } = match.params || {}
    const authenToken = localStorage.getItem(AUTHEN_TOKEN)

    if (id) {
      const input = {
        id,
        authenToken,
      }
      this.setState({ isLoading: true })
      const data = await getDetailCourse(input)

      if (data.isSuccess) {
        this.setState({
          isLoading: false,
          isGetData: true,
        })
        const { name, price, image, max_diner, min_diner, content, translations } = data.data
        let nameVi = ''
        let nameJa = ''
        let nameKo = ''
        let noteVi = ''
        let noteJa = ''
        let noteKo = ''
        let idLanguageVi = ''
        let idLanguageJa = ''
        let idLanguageKo = ''
        if (Array.isArray(translations)) {
          const indexvi = translations.findIndex(i => i.language_id === languageList.vi)
          if (indexvi >= 0) {
            nameVi = translations[indexvi].name_translation
            noteVi = translations[indexvi].content_translation
            idLanguageVi = translations[indexvi].id
          }

          const indexja = translations.findIndex(i => i.language_id === languageList.ja)
          if (indexja >= 0) {
            nameJa = translations[indexja].name_translation
            noteJa = translations[indexja].content_translation
            idLanguageJa = translations[indexja].id
          }

          const indexko = translations.findIndex(i => i.language_id === languageList.ko)
          if (indexko >= 0) {
            nameKo = translations[indexko].name_translation
            noteKo = translations[indexko].content_translation
            idLanguageKo = translations[indexko].id
          }
        }
        this.setState({
          name: name || '',
          nameVi,
          nameJa,
          nameKo,
          price: price || 0,
          image,
          minRange: min_diner,
          maxRange: max_diner,
          note: content || '',
          noteVi,
          noteJa,
          noteKo,
          idLanguageVi,
          idLanguageJa,
          idLanguageKo,
        })
      } else this.setState({ error: 'somethingWrong', isLoading: false, isGetData: false })
    }
  }

  convertPrice = text => {
    let newText = text
    try {
      newText = text.split('')
      newText = newText.filter(item => item !== '.')
      newText = newText.join('')
    } catch (error) {
      // console.log(error)
    }
    return Number(newText)
  }

  onCreateCourse = async () => {
    const {
      name,
      price,
      imageUpload,
      minRange,
      maxRange,
      note,
      nameVi,
      nameJa,
      nameKo,
      noteVi,
      noteJa,
      noteKo,
    } = this.state

    const authenToken = localStorage.getItem(AUTHEN_TOKEN)
    const { history } = this.props

    const translations = [
      { id: languageList.vi, name: nameVi, content: noteVi },
      { id: languageList.ja, name: nameJa, content: noteJa },
      { id: languageList.ko, name: nameKo, content: noteKo },
    ]

    const input = {
      name,
      price: this.convertPrice(price),
      imageUpload,
      authenToken,
      minDiner: minRange,
      maxDiner: maxRange,
      content: note,
      translations,
    }

    this.setState({
      isLoadingCreate: true,
    })
    const data = await createCourse(input)

    if (data.isSuccess) {
      this.setState({
        isLoadingCreate: false,
      })
      history.goBack()
    } else this.setState({ error: 'somethingWrong', isLoadingCreate: false })
  }

  onEditCourse = async () => {
    const {
      name,
      price,
      imageUpload,
      minRange,
      maxRange,
      note,
      nameVi,
      nameJa,
      nameKo,
      noteVi,
      noteJa,
      noteKo,
      idLanguageVi,
      idLanguageJa,
      idLanguageKo,
    } = this.state

    const authenToken = localStorage.getItem(AUTHEN_TOKEN)
    const { match } = this.props
    const { id } = match.params || {}
    const { history } = this.props
    const translations = [
      { id: languageList.vi, idLanguage: idLanguageVi, name: nameVi, content: noteVi },
      { id: languageList.ja, idLanguage: idLanguageJa, name: nameJa, content: noteJa },
      { id: languageList.ko, idLanguage: idLanguageKo, name: nameKo, content: noteKo },
    ]
    const input = {
      name,
      id,
      price: this.convertPrice(price),
      imageUpload,
      authenToken,
      minDiner: minRange,
      maxDiner: maxRange,
      content: note,
      translations,
    }

    this.setState({
      isLoadingCreate: true,
    })
    const data = await editCourse(input)

    if (data.isSuccess) {
      this.setState({
        isLoadingCreate: false,
      })
      history.goBack()
    } else this.setState({ error: 'somethingWrong', isLoadingCreate: false })
  }

  checkValidate = (checkAll, nameInput = '', valueInput = '') => {
    const { validate, name, imageUpload, image, minRange, maxRange } = this.state
    // const checkValidate = validate
    const textErrorName = 'courseError.requiredName'
    const textErrorPrice = 'courseError.validatePrice'
    const textErrorImage = 'courseError.requiredImage'
    const textErrorMinRange = 'rangeBook.errorMinBook'
    const textErrorMaxRange = 'rangeBook.errorMaxBook'

    let value = valueInput || ''
    try {
      value = typeof valueInput === 'string' ? valueInput.trim() : valueInput

      const checkName =
        ((value.length === 0 || value.length > 200) && nameInput === 'name') ||
        (checkAll && name.trim().length === 0)

      const checkPrice = (value.length === 0 || value.length > 15) && nameInput === 'price'
      const checkMinRage = nameInput === 'minRange' && minRange > maxRange

      const checkMaxRage = nameInput === 'maxRange' && minRange > maxRange
      if (checkName) validate.name = textErrorName
      if (checkPrice) validate.price = textErrorPrice
      if (checkMinRage) validate.minRange = textErrorMinRange
      if (checkMaxRage) validate.maxRange = textErrorMaxRange
      if (value.length !== 0 && nameInput === 'name') validate.name = null
      if (value.length !== 0 && nameInput === 'price') validate.price = null
      if (minRange < maxRange) {
        validate.minRange = null
        validate.maxRange = null
      }

      if (checkAll && !imageUpload && !image) validate.image = textErrorImage

      // console.log({ validate })
      this.setState({ validate })
    } catch (e) {
      console.log(e)
    }
  }

  resetValidate = name => {
    const { validate } = this.state
    // const checkValidate = validate
    if (name === 'name') validate.name = null
    if (name === 'price') validate.price = null
    this.setState({ validate })
  }

  onSendData = () => {
    const { match } = this.props
    const { id } = match.params || {}
    const { name, price, imageUpload, image, validate, errorMinBook, errorMaxBook } = this.state
    // chuwa co name, price, ảnh, category hoặc là category ko tồn tại
    const checkValidateData =
      name.trim().length === 0 ||
      price.length === 0 ||
      (!imageUpload && !image) ||
      validate.minRange ||
      validate.maxRange ||
      errorMinBook ||
      errorMaxBook
    if (checkValidateData) {
      this.checkValidate(true)
    } else if (id) this.onEditCourse()
    else this.onCreateCourse()
  }

  handleChange = event => {
    const { id, value } = event.target
    // console.log({value})
    if (id === 'price') {
      const lastText = value[value.length - 1]
      const regex = /[0-9]/g
      const checkRegex = lastText && lastText.match(regex)
      if (
        ((checkRegex && checkRegex.length > 0) || value === '') &&
        value.length < maxLengthPrice + 4
      )
        this.setState({ [id]: this.convertPrice(value) })
    } else if (id === 'name') {
      if (value.length < 201) this.setState({ [id]: value.replace(regexEmoji, '') })
    } else if (id === 'minRange' || id === 'maxRange') {
      if (value.length <= 100)
        this.setState({
          [id]: Number(value.replace(/[\D]/g, '')) > 100 ? 100 : Number(value.replace(/[\D]/g, '')),
        })
    } else if (id === 'note' || id === 'noteVi' || id === 'noteJa' || id === 'noteKo') {
      if (value.length < 1025) this.setState({ [id]: value })
    } else this.setState({ [id]: value })
  }

  onChooseImage = async event => {
    const { validate } = this.state
    const file = event.target.files[0]
    if (file) {
      const resizedImage = await compress.compress([file], {
        size: 2, // the max size in MB, defaults to 2MB
        quality: 1, // the quality of the image, max is 1,
        maxWidth: 2000,
        maxHeight: 2000,
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
      })
      const img = resizedImage[0]
      const base64str = img.data
      const imgExt = img.ext
      const resizedFile = Compress.convertBase64ToFile(base64str, imgExt)
      if (
        (file && file.type !== 'image/png' && file.type !== 'image/jpeg') ||
        file.size > 10000000
      ) {
        const textErrorImage = 'validateImage'

        validate.image = textErrorImage
        this.setState({ validate })
      } else {
        validate.image = null
        // eslint-disable-next-line no-undef
        const fileBlob = new File([resizedFile], file.name, { type: resizedFile.type })
        this.setState({ imageUpload: fileBlob, validate, image: URL.createObjectURL(fileBlob) })
      }
    }
  }

  onChangeTextVi = event => {
    const { value } = event.target
    const realValue = value.replace(regexEmoji, '')
    if (realValue.length < 201) this.setState({ nameVi: realValue })
  }

  onChangeTextJa = event => {
    const { value } = event.target
    const realValue = value.replace(regexEmoji, '')
    if (realValue.length < 201) this.setState({ nameJa: realValue })
  }

  onChangeTextKo = event => {
    const { value } = event.target
    const realValue = value.replace(regexEmoji, '')
    if (realValue.length < 201) this.setState({ nameKo: realValue })
  }

  onChangeMinBook = event => {
    const { value } = event.nativeEvent.target
    this.setState({
      minRange: value.replace(/[\D]/g, '') > 100 ? 100 : value.replace(/[\D]/g, ''),
      errorMinBook: '',
    })
  }

  onChangeMaxBook = event => {
    const { value } = event.nativeEvent.target
    this.setState({
      maxRange: value.replace(/[\D]/g, '') > 100 ? 100 : value.replace(/[\D]/g, ''),
      errorMaxBook: '',
    })
  }

  onBlurMinBook = () => {
    const { minRange, maxRange } = this.state
    const convertMinBook = Number(minRange)
    const convertMaxBook = Number(maxRange)

    if (convertMinBook < 1 && !isEmptyContent(minRange)) {
      this.setState({
        errorMinBook: 'rangeBook.errorRequired',
      })
    }
    if (!isEmptyContent(minRange) && !isEmptyContent(maxRange)) {
      if (convertMinBook >= convertMaxBook) {
        this.setState({
          errorMinBook: 'rangeBook.errorMinBook',
        })
      } else if (convertMaxBook > convertMinBook && convertMaxBook >= 1 && convertMinBook >= 1) {
        this.setState({
          errorMaxBook: '',
          errorMinBook: '',
        })
      }
    }
  }

  onBlueMaxBook = () => {
    const { minRange, maxRange } = this.state
    const convertMinBook = Number(minRange)
    const convertMaxBook = Number(maxRange)
    if (convertMaxBook < 1 && !isEmptyContent(maxRange)) {
      this.setState({
        errorMaxBook: 'rangeBook.errorRequired',
      })
    }
    if (!isEmptyContent(minRange) && !isEmptyContent(maxRange)) {
      if (convertMaxBook <= convertMinBook) {
        this.setState({
          errorMaxBook: 'rangeBook.errorMaxBook',
        })
      } else if (convertMaxBook > convertMinBook && convertMaxBook >= 1 && convertMinBook >= 1) {
        this.setState({
          errorMaxBook: '',
          errorMinBook: '',
        })
      }
    }
  }

  /**
   * Render row
   */
  renderRow = ({ key, value, validate, props, inputs, id, name, maxLength }) => {
    const { language, classes } = this.props
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <p>{key}</p>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <InputOutline
            id={id}
            formControlProps={{
              className: classes.containerInput,
              fullWidth: true,
              ...props,
            }}
            inputProps={{
              className: validate && classes.inputValidate,
              value,
              name,
              size: 'small',
              ...inputs,
            }}
          />
          <GridContainer>
            <GridItem xs={12} sm={12} md={10}>
              {validate && (
                <div>
                  <p className={classes.textError}>{language.t(validate)}</p>
                </div>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <p className={`${validate && classes.textError} ${classes.textLength}`}>
                {value.length}/{maxLength}
              </p>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    )
  }

  renderContent = () => {
    const { isLoading, isGetData, minRange, maxRange } = this.state
    const { classes, language } = this.props

    if (isLoading) {
      return (
        <div className={classes.contentLoader}>
          <CircularProgress
            size={40}
            color="inherit"
            style={{ color: '#000', marginBottom: '20px' }}
          />
        </div>
      )
    }
    const {
      name,
      price,
      image,
      imageUpload,
      isLoadingCreate,
      validate,
      note,
      nameVi,
      nameJa,
      nameKo,
      noteVi,
      noteJa,
      noteKo,
      errorMaxBook,
      errorMinBook,
    } = this.state

    const { match } = this.props
    const { id } = match.params || {}
    const titleCard = id ? 'editCourse' : 'createCourse'
    const textUpload = image || imageUpload ? 'edit' : 'addNewImage'
    if (isGetData) {
      return (
        <>
          {this.renderRow({
            key: `${language.t('nameCourse')}(*)`,
            value: name,
            name: 'name',
            id: 'name',
            maxLength: 200,
            validate: validate.name,
            props: {
              onChange: this.handleChange,
              onFocus: () => this.resetValidate('name'),
              onBlur: () => this.checkValidate(false, 'name', name),
            },
          })}
          {this.renderRow({
            key: `${language.t('nameCourse')} (${language.t('language.vi')})`,
            value: nameVi,
            name: 'nameVi',
            id: 'nameVi',
            maxLength: 200,
            props: {
              onChange: this.onChangeTextVi,
            },
          })}
          {this.renderRow({
            key: `${language.t('nameCourse')} (${language.t('language.ja')})`,
            value: nameJa,
            name: 'nameJa',
            id: 'nameJa',
            maxLength: 200,
            props: {
              onChange: this.onChangeTextJa,
            },
          })}
          {this.renderRow({
            key: `${language.t('nameCourse')} (${language.t('language.ko')})`,
            value: nameKo,
            name: 'nameKo',
            id: 'nameKo',
            maxLength: 200,
            props: {
              onChange: this.onChangeTextKo,
            },
          })}

          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <p>{`${language.t('priceCourse')}(*)`}</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <InputOutline
                id="price"
                formControlProps={{
                  className: classes.containerInput,
                  fullWidth: true,
                  onChange: this.handleChange,
                  onFocus: () => this.resetValidate('price'),
                  onBlur: () => this.checkValidate(false, 'price', price),
                }}
                inputProps={{
                  className: validate.price && classes.inputValidate,
                  value: formatMoney(price),
                  name: 'price',
                }}
              />
              <GridContainer>
                <GridItem xs={12} sm={12} md={10}>
                  {validate.price && (
                    <div>
                      <p className={classes.textError}>{language.t(validate.price)}</p>
                    </div>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <p className={`${validate.price && classes.textError} ${classes.textLength}`}>
                    {`${price.toString().length}/${maxLengthPrice}`}
                  </p>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem>
              <p>{`${language.t('foodDetail.unit')}: VND`}</p>
            </GridItem>
          </GridContainer>
          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <p>{`${language.t('courseRange')}`}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} className={classes.rangeContainer}>
              <div className={classes.inputRangeContainer}>
                <InputOutline
                  id="minRange"
                  formControlProps={{
                    className: classes.containerInput,
                    fullWidth: true,
                    onChange: this.onChangeMinBook,
                    onBlur: this.onBlurMinBook,
                  }}
                  inputProps={{
                    className: classes.inputRange,
                    value: minRange,
                    name: 'minRange',
                    placeholder: language.t('placeholderMinRange'),
                  }}
                />
                {errorMinBook && (
                  <span className={classes.textError}>{language.t(errorMinBook)}</span>
                )}
              </div>

              <p
                style={{
                  textAlign: 'center',
                  fontSize: '30px',
                  margin: '30px 10px 0 10px',
                }}
              >
                ~
              </p>
              <div className={classes.inputRangeContainer}>
                <InputOutline
                  id="maxRange"
                  formControlProps={{
                    className: classes.containerInput,
                    fullWidth: true,
                    onChange: this.onChangeMaxBook,
                    onBlur: this.onBlueMaxBook,
                  }}
                  inputProps={{
                    className: classes.inputRange,
                    value: maxRange,
                    name: 'maxRange',
                    placeholder: language.t('placeholderMaxRange'),
                  }}
                />
                {errorMaxBook && (
                  <span className={classes.textError}>{language.t(errorMaxBook)}</span>
                )}
              </div>
            </Grid>
          </Grid>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <p>{`${language.t('imageCourse')}(*)`}</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <GridContainer>
                <GridItem>
                  <div className={classes.containerUpload}>
                    {image && (
                      <div className={classes.media}>
                        <CardMedia className={classes.media} image={image} title="Ảnh minh hoạ" />
                      </div>
                    )}
                    <Button
                      variant="contained"
                      component="label"
                      color="primary"
                      className={`${classes.buttonSend} ${classes.buttonUpload}`}
                    >
                      {language.t(textUpload)}
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        accept="image/png, image/jpeg"
                        onChange={this.onChooseImage}
                      />
                    </Button>
                    <div>{imageUpload && <p>{imageUpload.name}</p>}</div>
                  </div>
                </GridItem>
              </GridContainer>
              {validate.image && (
                <div>
                  <p className={classes.textError}>{language.t(validate.image)}</p>
                </div>
              )}
            </GridItem>
          </GridContainer>
          {this.renderRow({
            key: `${language.t('detail')}`,
            value: note,
            name: 'note',
            maxLength: 1024,
            id: 'note',
            props: {
              onChange: this.handleChange,
            },
            inputs: {
              multiline: true,
              rows: 5,
            },
          })}
          {this.renderRow({
            key: `${language.t('detail')} (${language.t('language.vi')})`,
            value: noteVi,
            name: 'noteVi',
            id: 'noteVi',
            maxLength: 1024,
            props: {
              onChange: this.handleChange,
            },
            inputs: {
              multiline: true,
              rows: 5,
            },
          })}
          {this.renderRow({
            key: `${language.t('detail')} (${language.t('language.ja')})`,
            value: noteJa,
            name: 'noteJa',
            id: 'noteJa',
            maxLength: 1024,
            props: {
              onChange: this.handleChange,
            },
            inputs: {
              multiline: true,
              rows: 5,
            },
          })}
          {this.renderRow({
            key: `${language.t('detail')} (${language.t('language.ko')})`,
            value: noteKo,
            name: 'noteKo',
            id: 'noteKo',
            maxLength: 1024,
            props: {
              onChange: this.handleChange,
            },
            inputs: {
              multiline: true,
              rows: 5,
            },
          })}
          <GridContainer>
            <GridItem>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonSend}
                onClick={this.onSendData}
              >
                {isLoadingCreate && (
                  <CircularProgress
                    size={20}
                    color="inherit"
                    style={{ color: '#000', marginRight: 4 }}
                  />
                )}
                {language.t(titleCard)}
              </Button>
            </GridItem>
          </GridContainer>
        </>
      )
    }
    return null
  }

  render() {
    const { classes, language } = this.props
    const { error } = this.state
    const { match } = this.props
    const { id } = match.params || {}
    const titleCard = id ? 'editCourse' : 'createCourse'
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            {error && (
              <Alert variant="outlined" severity="error">
                {language.t(error)}
              </Alert>
            )}
            <Card>
              <CardHeader plain color="primary">
                <h4 className={classes.cardTitleWhite}>{language.t(titleCard)}</h4>
              </CardHeader>
              <CardBody>{this.renderContent()}</CardBody>
              {/* <CardFooter>
                <Button color="primary">Update Profile</Button>
              </CardFooter> */}
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

NewBookingCourse.propTypes = {
  classes: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  language: PropTypes.any,
}

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  infoContainer: {
    width: '100%',
    marginTop: '20px',
  },
  infoText: {
    maxWidth: '100%',
    padding: '0px 20px',
    textAlign: 'justify',
  },
  header: {
    backgroundImage: `url(${bgImage}) !important`,
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(79, 76, 73,.4) !important',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 center',
  },
  headerContent: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: '3px',
    padding: '20px 30px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerImage: {
    width: '120px',
    height: '120px',
    objectFit: 'contain',
    marginRight: '30px',
  },
  headerText: {
    fontSize: '25px',
    color: '#fff',
  },
  imageLoader: {
    width: '120px',
    height: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#f2f2f2',
    marginRight: '30px',
  },
  contentLoader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formControl: {
    margin: '20px',
  },
  rangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '9px',
  },
  inputRangeContainer: {
    width: '35%',
    display: 'flex',
    flexDirection: 'column',
  },
  media: {
    width: '240px',
    height: '240px',
    backgroundSize: 'contain',
    margin: '10px 20px 10px 0',
    borderRadius: '8px',
    // paddingTop: '56.25%', // 16:9
  },
  buttonUpload: {
    height: 36,
    // marginBottom: 20,
    marginRight: 20,
    // marginLeft: 20,
  },
  containerUpload: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonSend: {
    marginTop: 20,
    backgroundColor: mainColor,
  },
  inputValidate: {
    borderColor: 'red',
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: 6,
  },
  inputRange: {
    width: '100%',
  },
  containerInput: {
    marginTop: 0,
    paddingBottom: 0,
  },
  textError: {
    color: 'red',
    margin: 0,
  },
  textLength: {
    textAlign: 'end',
    margin: 0,
  },
}

const mapStateToProps = state => {
  return {
    language: state.languageReducer.language,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(NewBookingCourse))

const styles = {
  container: {
    flex: 1,
    padding: '20px',
    borderRadius: '6px',
    background: '#fff',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
  },
  contentLoader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  eventStyle: {
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  gridContainer: { marginTop: '30px', marginBottom: '25px' },
}

export default styles

import I18n from 'i18n-js'
// import * as RNLocalize from 'react-native-localize'
import vi from './locales/vi'
import ja from './locales/ja'
import ko from './locales/ko'
import en from './locales/en'
import { LOCALE, languageKey } from '../constants/define'
// const locales2 = RNLocalize.getLocales()

// const locales = [
//   {countryCode: 'US', languageTag: 'en-US', languageCode: 'en', isRTL: false},
//   {countryCode: 'VN', languageTag: 'vi-VN', languageCode: 'vi', isRTL: false},
// ]

export const getlocaleI18n = localeSelect => {
  if (localeSelect === languageKey.vi) {
    return {
      isRTL: false,
      languageTag: 'vi-VN',
    }
  }
  if (localeSelect === languageKey.ko) {
    return {
      isRTL: false,
      languageTag: 'ko-KR',
    }
  }
  if (localeSelect === languageKey.en) {
    return {
      isRTL: false,
      languageTag: 'en-EN',
    }
  }

  return {
    isRTL: false,
    languageTag: 'ja-JP',
  }
}

export const setI18nConfig = localeSelect => {
  if (localeSelect) {
    localStorage.setItem(LOCALE, localeSelect)
  } else {
    localStorage.setItem(LOCALE, 'ja')
  }
  const locale = getlocaleI18n(localeSelect)

  // I18n.defaultLocale = 'ja-JP'
  I18n.locale = locale.languageTag

  I18n.fallbacks = true
  I18n.translations = {
    vi,
    ja,
    ko,
    en,
  }

  // if (typeof callback === 'function') callback()
}

const defaultLocale = localStorage.getItem(LOCALE)
setI18nConfig(defaultLocale)

export default I18n

module.exports = {
  // product
  s3Url: 's3://booking.capichiapp.com',
  baseUrl: 'https://store.capichiapp.com/',
  // staging
  // s3Url: 's3://stg.delivery.capichiapp.com',
  // baseUrl: 'https://stg.store.capichiapp.com/',
  // dev
  // s3Url: 's3://dev.booking.capichiapp.com',
  // baseUrl: 'https://dev.store.capichiapp.com/',
  // s3Url: 's3://delivery.capichidns.co.uk',
}

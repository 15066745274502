// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
// import "babel-polyfill"
import React from 'react'
import ReactDOM from 'react-dom'
// import './index.css';
import App from './app'
import * as serviceWorker from './serviceWorker'

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then(function(registration) {
      // console.log('registration', registration)
      console.log('Registration successful, scope is:', registration.scope)
    })
    .catch(function(err) {
      console.log('Service worker registration failed, error:', err)
    })
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import request from './request'
import { listCourseApi } from './config'
import { getMessageErrorFormServer } from '../commons'

export const getListCourse = async ({ authen, page, limit = 10, cancel }) => {
  try {
    const result = await request(authen, cancel).get(`${listCourseApi}?page=${page}&limit=${limit}`)
    const { data, status } = result
    if (status === 200)
      return {
        isSuccess: true,
        data: data && Array.isArray(data.data) ? data.data : [],
        maxCount: data && data.paging && data.paging.total,
      }
    return {
      isSuccess: false,
      message: 'a',
    }
  } catch (e) {
    return {
      isSuccess: false,
      message: getMessageErrorFormServer(e),
    }
  }
}

export const getDetailCourse = async ({ authenToken, id }) => {
  try {
    const result = await request(authenToken).get(`${listCourseApi}/${id}`)

    const { data, status } = result
    if (status === 200)
      return {
        isSuccess: true,
        data: data ? data.data : {},
      }
    return {
      isSuccess: false,
      message: 'a',
    }
  } catch (e) {
    return {
      isSuccess: false,
      message: getMessageErrorFormServer(e),
    }
  }
}

export const deleteCourse = async ({ authenToken, id }) => {
  try {
    const result = await request(authenToken).delete(`${listCourseApi}/${id}`)

    const { data, status } = result
    if (status === 200)
      return {
        isSuccess: true,
        data: data ? data.data : {},
      }
    return {
      isSuccess: false,
      message: 'a',
    }
  } catch (e) {
    return {
      isSuccess: false,
      message: getMessageErrorFormServer(e),
    }
  }
}

export const createCourse = async ({
  authenToken,
  name,
  imageUpload,
  price,
  minDiner,
  maxDiner,
  content,
  translations,
}) => {
  try {
    const formData = new FormData()
    if (name) formData.append('course[name]', name)
    if (price) formData.append('course[price]', price)
    if (imageUpload) formData.append('course[image]', imageUpload)
    if (minDiner) formData.append('course[min_diner]', minDiner)
    if (maxDiner) formData.append('course[max_diner]', maxDiner)
    if (content) formData.append('course[content]', content)
    if (Array.isArray(translations)) {
      translations.forEach((element, index) => {
        formData.append(
          `course[course_translations_attributes][${index}][name_translation]`,
          element.name
        )
        formData.append(
          `course[course_translations_attributes][${index}][content_translation]`,
          element.content
        )
        formData.append(`course[course_translations_attributes][${index}][language_id]`, element.id)
      })
    }
    const response = await request(authenToken).post(`${listCourseApi}`, formData)
    const { data, status } = response
    if (status === 200)
      return {
        isSuccess: true,
        data: data.data ? data.data : {},
      }
    return {
      isSuccess: false,
      message: 'error',
    }
  } catch (e) {
    return {
      isSuccess: false,
      message: getMessageErrorFormServer(e),
    }
  }
}

export const editCourse = async ({
  authenToken,
  name,
  imageUpload,
  price,
  id,
  statusPopular,
  minDiner,
  maxDiner,
  content,
  translations,
}) => {
  try {
    const formData = new FormData()
    if (name) formData.append('course[name]', name)
    if (price) formData.append('course[price]', price)
    if (imageUpload) formData.append('course[image]', imageUpload)
    if (minDiner) {
      formData.append('course[min_diner]', minDiner)
    } else {
      formData.append('course[min_diner]', null)
    }

    if (maxDiner) {
      formData.append('course[max_diner]', maxDiner)
    } else {
      formData.append('course[max_diner]', null)
    }
    if (content) formData.append('course[content]', content.trim())
    if (Array.isArray(translations)) {
      translations.forEach((element, index) => {
        const name_translation =
          typeof element.name === 'string' ? element.name.trim() : element.name
        formData.append(
          `course[course_translations_attributes][${index}][name_translation]`,
          name_translation
        )
        const content_translation =
          typeof element.content === 'string' ? element.content.trim() : element.content
        formData.append(
          `course[course_translations_attributes][${index}][content_translation]`,
          content_translation
        )
        formData.append(`course[course_translations_attributes][${index}][language_id]`, element.id)
        formData.append(`course[course_translations_attributes][${index}][id]`, element.idLanguage)
      })
    }
    if (statusPopular === true || statusPopular === false)
      formData.append('course[popular]', statusPopular)
    const response = await request(authenToken).put(`${listCourseApi}/${id}`, formData)
    const { data, status } = response
    if (status === 200)
      return {
        isSuccess: true,
        data: data.data ? data.data : {},
      }
    return {
      isSuccess: false,
      message: 'error',
    }
  } catch (e) {
    return {
      isSuccess: false,
      message: getMessageErrorFormServer(e),
    }
  }
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Dialog } from '@material-ui/core'
import React, { useState } from 'react'
import PropTypes from 'prop-types'

const DialogChooseBookingTime = (props, ref) => {
  const [show, setShow] = useState(false)
  const [data, setData] = useState([])

  const onCancel = () => {
    setShow(false)
  }

  React.useImperativeHandle(ref, () => ({
    setData: value => {
      setData(value)
    },
    show: () => {
      setShow(true)
    },
  }))

  const { onChooseTime, checkEnableTime } = props

  const handleClick = time => {
    if (!checkEnableTime(time, true)) {
      return
    }
    onChooseTime(time)
    onCancel()
  }

  return (
    <Dialog
      id="dialog-detail-event"
      classes={{ paperFullWidth: 'dialog-login-container' }}
      fullWidth
      open={show}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
    >
      <div className="view-time">
        {data.map(r => (
          <div
            className={`txt-time-booking txt-view-time-dialog ${
              checkEnableTime(r) ? '' : 'disable-time'
            }`}
            key={r}
            onClick={() => handleClick(r)}
          >
            {r}
          </div>
        ))}
      </div>
    </Dialog>
  )
}

DialogChooseBookingTime.propTypes = {
  onChooseTime: PropTypes.any,
  checkEnableTime: PropTypes.any,
}

export default React.forwardRef(DialogChooseBookingTime)

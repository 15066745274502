import request from './request'
import { listNotificationApi, readCountNotificationApi } from './config'
import { getMessageErrorFormServer } from '../commons'

// eslint-disable-next-line import/prefer-default-export
export const getListNotification = async ({ authen, page, limit = 10, cancel }) => {
  try {
    const result = await request(authen, cancel).get(
      `${listNotificationApi}?page=${page}&limit=${limit}`
    )
    const { data, status } = result
    if (status === 200)
      return {
        isSuccess: true,
        data: data && Array.isArray(data.data) ? data.data : [],
        maxCount: data && data.paging && data.paging.total,
      }
    return {
      isSuccess: false,
      message: 'a',
    }
  } catch (e) {
    return {
      isSuccess: false,
      message: getMessageErrorFormServer(e),
    }
  }
}

export const getBadgetNotification = async ({ authen, cancel }) => {
  try {
    const result = await request(authen, cancel).get(`${readCountNotificationApi}`)
    const { data, status } = result
    if (status === 200)
      return {
        isSuccess: true,
        data: data ? data.data : {},
      }
    return {
      isSuccess: false,
      message: 'a',
    }
  } catch (e) {
    return {
      isSuccess: false,
      message: getMessageErrorFormServer(e),
    }
  }
}

export const getDetailNotification = async ({ authen, id, cancel }) => {
  try {
    const result = await request(authen, cancel).get(`${listNotificationApi}/${id}`)
    const { data, status } = result
    if (status === 200)
      return {
        isSuccess: true,
        data: data ? data.data : {},
      }
    return {
      isSuccess: false,
      message: 'a',
    }
  } catch (e) {
    return {
      isSuccess: false,
      message: getMessageErrorFormServer(e),
    }
  }
}

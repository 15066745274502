// eslint-disable-next-line import/prefer-default-export
export const getTextFromBacket = itemText => {
  try {
    const openIndex = itemText.search('<')
    const closeIndex = itemText.search('>')
    const nameItem = itemText.substring(openIndex + 1, closeIndex)
    const contentItem = itemText.substring(closeIndex + 1)
    return { nameItem, contentItem }
  } catch (e) {
    // console.warn(e)
  }
  return null
}

/**
 * Function check giá trị có phải Null hoặc rỗng hay ko
 * @param {*} value
 */
export const isEmptyContent = value => {
  if (value === null || value === '') {
    return true
  }
  return false
}

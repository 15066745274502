export default {
  pageTitle: 'Capichi Booking',
  storeInfo: '레스토랑 정보',
  name: '점명',
  hello: '안녕하세요',
  validatePassword: '암호는 8 자 이상이어야하며 25자를 초과 할 수 없습니다.',
  validateField: '모든 란에 기입하십시오',
  email: '이메일 주소',
  password: '비밀번호',
  profileName: '프로필',
  logOut: '로그 아웃',
  signIn: '로그인',
  address: '住所',
  //   address: '주소',
  status: '활성',
  shippingFee: '배송 요금',
  openTime: '영업 시간',
  image: '이미지',
  note: '추가 메시지',
  delete: '삭제',
  addNew: '추가',
  time: {
    min: '폐점시간은 개점시간보다 큰 숫자가 아니면 안됩니다',
    max: '영업 시간이 잘못되었습니다. 다시 확인해주십시오. ',
    open: '점포의 개점 시간을 입력하십시오',
    close: '점포의 폐점 시간을 입력하십시오',
  },
  result: {
    error: '매장 정보 업데이트에 실패했습니다. 다시 시도해주세요 ',
    success: '매장 정보는 성공적으로 업데이트되었습니다',
  },
  price: '가격',
  errorCustomer: {
    validateEmail: '올바른 형식으로 이메일 주소를 입력하십시오',
  },
  save: '저장',
  cancel: '취소',
  edit: '편집',
  foodDetail: {
    unit: '가격',
    delete: '삭제',
    confirmDelete: '정말 이메뉴를 삭제 하시겠습니까? ',
  },
  ok: '확인',
  validateImage: '최대 10MB의 JPG 또는 PNG를 선택하십시오',
  orderResult: {
    error: '주문 정보 업데이트에 실패했습니다. 다시 시도해주세요 ',
    error1: '매장 정보 업데이트에 실패했습니다. 다시 시도해주세요 ',
    success: '주문 정보는 성공적으로 업데이트되었습니다',
    timeout: '집배원 취소후 60 분을 초과했기 때문에 다른 배달원을 부를 수 없습니다. ',
    delivery: '지금은 배달원을 취소 할 수 없습니다. ',
  },
  somethingWrong: '매장 정보 업데이트에 실패했습니다. 다시 시도해주세요 ',
  active: '표시',
  menuValidate: {
    validatePrice: '메뉴의 가격은 1 자리 ~ 15 자리의 숫자입니다',
    requiredName: '메뉴 이름의 길이는 1~200 문자입니다',
    requiredImage: '메뉴의 이미지를 추가하십시오',
    requireCategory: '메뉴를 등록 할 카테고리를 선택하십시오',
  },
  addNewImage: '이미지 추가',
  notification: '통지',
  notifyList: '알림 목록',
  notifyDescription: '알림 합계',
  notifySku: '통지 번호',
  notifyTitle: '제목',
  notifyEmpty: '현재 알림이 없습니다',
  detail: '세부사항',
  rowPerPage: '페이지당 행개수',
  error300: '서버가 요청을 처리 할 수 ​​없습니다',
  error500: '서버에 액세스 할 수 없습니다. 다시 시도해주세요. ',
  error401: '이 기능을 이용하기 위해서는 로그인하십시오. ',
  error404: '내용이 없거나 삭제 되엇을수 있습니다. 다시 시도해주세요. ',
  error400: '콘텐츠가 없습니다. 다시 시도해주세요. ',

  noteConfirmPhone: '주소를 선택하기 전에 전화 번호를 확인하십시오',
  retry: '확인',
  confirm: '확인',
  errorAuthen: '로그인하는 동안 문제가 발생했습니다. 이메일 주소와 비밀번호를 확인하십시오. ',
  category: '카테고리',
  language: {
    vi: 'Tiếng Việt',
    ja: '日本語',
    ko: '한국',
    en: 'English',
  },
  /** Booking setting */
  storeSetting: '점포설정',
  regularHoliday: '휴무일',
  am: '낮',
  pm: '밤',
  bookingSetting: '예약설정',
  bookingToday: '예약가능 시간 (시)',
  before: '이전',
  bookingRange: '예약가능 인원 (명) (*)',
  errorDay: '영업일을 입력하십시오',
  placeholderMinRange: '최소: 1',
  placeholderMaxRange: '최대: 100',
  courseEmpty: '아직 코스가 등록되어 있지 않습니다',
  popular: '인기',
  errorRequiredLogin:
    '아직 예약기능 이용 점포에 등록되어 있지 않습니다. Capichi 운영 사무실에 문의하십시오. ',
  rangeBook: {
    errorRequired: '1인 이하의 인원은 입력 할 수 없습니다.',
    errorMinBook: '최소 인원은 최대 인원수보다 작지 않으면 안됩니다',
    errorMaxBook: '최대 인원은 최소 인원보다 크지 않으면 안됩니다',
    required: '정보를 모두 입력하십시오',
  },
  resetSetting: '설정 재설정',
  notBookingDay: '예약을 받아들이지 않는 시간',
  nonReservableSetting: '예약을 받지 않는 시간을 설정',
  reservableAll: '하루종일',
  reservableSelect: '시간을 선택',
  cannotBookingAllDay: '하루종일 예약을 할 수 없습니다.',
  bookingCourseList: '예약 코스 목록',
  bookingCourseDescription: '모든 예약목록을 표시',
  bookingCourse: {
    id: '예약 코스 ID',
    info: '예약 코스 정보',
    detail: '세부사항',
    action: '행동',
    create: '예약 코스를 등록',
    delete: '예약 코스를 삭제',
    confirmDelete: '이 예약과정을 삭제 하시겠습니까?',
  },
  createCourse: '예약 코스를 등록',
  editCourse: '예약 코스를 수정',
  nameCourse: '예약 코스 명칭',
  priceCourse: '예약 코스의 가격',
  courseRange: '예약 코스 예약가능 인원',
  imageCourse: '예약 코스의 사진',
  courseError: {
    validatePrice: '예약 코스의 가격은 1 ~ 15 자리의 숫자입니다',
    requiredName: '예약 코스명칭은 1 ~ 200 자입니다',
    requiredImage: '예약 코스의 사진을 추가하십시오.',
  },
  bookingList: {
    title: '예약목록',
    description: '예약목록 보기',
    id: '예약 ID',
    user: '고객정보',
    people: '예약인원',
    date: '예약날짜',
    time: '예약시간',
    note: '추가정보',
    course: '예약 코스',
    action: '실행',
    empty: '아직 예약은 없읍니다',
    status: 'Trạng thái',
  },
  bookingAction: {
    confirm: '확인',
    cancel: '취소',
    doneConfirm: '확정',
    doneCancel: '취소됨',
    dialogConfirm: {
      label: '예약을 확정',
      title: '예약을 확정 하시겠습니까? ',
    },
    dialogCancel: {
      label: '예약취소',
      title: '예약을 취소 하시겠습니까? ',
    },
  },
  notificationBooking: {
    label: '통지목록',
    description: '통지목록 보기',
    id: '통지번호',
    title: '제목',
    idBooking: '예약번호',
    time: '시간',
    empty: '아직 통지는 없읍니다.',
  },
  next: '다음',
  emptyTime: '점포의 개점시간을 설정하십시오',
  day: {
    mon: '월',
    tue: '화',
    wed: '수',
    thu: '목',
    fri: '금',
    sat: '토',
    sun: '일',
  },
  statusBooking: {
    pending: '점포확인을 기다림',
    cancel: '취소됨',
    confirm: '확정',
    expired: '종료됨',
    cancelByOwner: '매장에서 취소',
    cancelByCustomer: '고객취소',
  },
  customer: {
    name: '이름',
    email: '편지',
    info: '고객 정보',
    phone: '전화 번호',
  },
  bookingInfo: '우대',
  timeBooking: '예약 시간',
  numberBooking: '인원',
  noteEdit: '편집 이유',
  course: '세트',
  choose_time: '시간',
  booking: {
    time_required: '시간을 선택하십시오. ',
    reason_required: '편집 이유를 입력하십시오. ',
    success: '예약 가입했습니다. ',
  },
  noteCancel: '예약 취소 이유',
  cancelBooking: '예약 취소',
  validateNoteCancel: '예약 취소의 이유를 입력하십시오. ',
  editBooking: '예약 정보를 편집',
}

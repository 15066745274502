/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-use-before-define */
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Close from '@material-ui/icons/Close'
import { CircularProgress, Button } from '@material-ui/core'
import { cancelBookingApi } from '../../api/booking'
import { AUTHEN_TOKEN } from '../../constants/define'

class DialogCancel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      bookingId: null,
      openDialog: false,
      isLoading: false,
      errorNote: '',
      note: '',
    }
  }

  /**
   * Close dialog function
   * @param {*} event
   */
  onCloseDialog = event => {
    event.preventDefault()
    this.setState({
      openDialog: false,
      errorNote: '',
      note: '',
    })
  }

  /**
   * Open dialog function
   */
  onOpenDialog = bookingId => {
    this.setState({
      openDialog: true,
      bookingId,
    })
  }

  /**
   * Cancel Booking
   */
  onCancelBooking = async () => {
    const { note, bookingId } = this.state
    const { onConfirmCancelBooking } = this.props
    const noteValue = note.trim()
    if (!noteValue) {
      this.setState({
        errorNote: 'validateNoteCancel',
      })
      return
    }
    try {
      this.setState({
        isLoading: true,
      })
      const payload = {
        authenToken: localStorage.getItem(AUTHEN_TOKEN),
        noteCancel: noteValue,
        bookingId,
      }
      const result = await cancelBookingApi(payload)
      if (result.isSuccess) {
        this.setState({
          isLoading: false,
          openDialog: false,
          errorNote: '',
          note: '',
        })
        if (onConfirmCancelBooking) {
          onConfirmCancelBooking(bookingId, true)
        }
      } else {
        this.setState({
          isLoading: false,
        })
        if (onConfirmCancelBooking) {
          onConfirmCancelBooking(bookingId, false)
        }
      }
    } catch (e) {
      console.warn(e)
      this.setState({
        isLoading: false,
      })
    }
  }

  onChangeNoteCancel = event => {
    const { value } = event.target
    this.setState({
      errorNote: '',
      note: `${value}`.slice(0, 1024),
    })
  }

  render() {
    const { openDialog, note, errorNote, isLoading } = this.state
    const { classes, language } = this.props
    return (
      <Dialog
        id="dialog-cancel"
        classes={{ paperFullWidth: 'dialog-cancel-container' }}
        fullWidth
        open={openDialog}
        //   onClose={onCancel}
        aria-labelledby="form-dialog-title"
      >
        <div className={classes.root}>
          <a href="#" className={classes.iconClose} onClick={this.onCloseDialog}>
            <Close color="#000" style={{ width: '30px', height: '30px' }} />
          </a>
          <p className={classes.headerNote}>{language.t('noteCancel')}</p>
          <TextField
            InputProps={{ value: note }}
            error={errorNote}
            variant="outlined"
            multiline
            rows={4}
            className={classes.input}
            onChange={this.onChangeNoteCancel}
            placeholder={language.t('noteCancel')}
            FormHelperTextProps={{
              style: { marginLeft: 0 },
            }}
            size="small"
          />
          {errorNote && <span className={classes.errorText}>{language.t(errorNote)}</span>}
          <div className={classes.actionContainer}>
            <Button
              variant="contained"
              color="inherit"
              style={{
                backgroundColor: 'rgb(245, 0, 87)',
                color: 'white',
              }}
              // disabled={disableSave}
              // className={classes.buttonfilter}
              onClick={this.onCancelBooking}
            >
              {isLoading && (
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{ color: '#fff', marginRight: '10px' }}
                />
              )}
              {language.t('cancelBooking')}
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

DialogCancel.propTypes = {
  classes: PropTypes.any,
  language: PropTypes.any,
  onConfirmCancelBooking: PropTypes.func,
}

const styles = {
  root: {
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box',
  },
  iconClose: { position: 'absolute', right: '10px', top: '10px', color: '#000' },
  headerNote: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: '600',
  },
  input: {
    width: '100%',
  },
  actionContainer: {
    width: '100%',
    margin: '15px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorText: {
    fontSize: '12px',
    color: 'red',
    lineHeight: '15px',
  },
}

export default withStyles(styles)(DialogCancel)

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox, Button } from '@material-ui/core'

import Dialog from '@material-ui/core/Dialog'
import '../../assets/css/Profile/styles.css'
import moment from 'moment'
import _ from 'lodash'

import { generateTimeRange } from '../../commons'

class ReservablePopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      currentDate: '',
      isCheckAll: false,
      time: [],
      nonReservableTime: { isAll: false, times: [] },
      onHandleChooseTime: () => {},
      preTime: [],
    }

    this.action = () => {}
  }

  // componentDidMount() {
  //     const
  //   this.generateTimeRange('10:14-12:24')
  // }

  show = (currentDate, currentTime, isSelected, onHandleChooseTime) => {
    this.setState({
      open: true,
      currentDate,
      time: currentTime,
      nonReservableTime: isSelected,
      onHandleChooseTime,
      isCheckAll: isSelected && isSelected.isAll ? isSelected.isAll : false,
    })
  }

  hide = () => {
    this.setState({ open: false })
  }

  checkAll = () => {
    const { nonReservableTime, isCheckAll, preTime } = this.state
    const currentNonReservable = {
      ...nonReservableTime,
      isAll: !isCheckAll,
      times: isCheckAll === false ? [] : preTime,
    }
    this.setState(preState => {
      return {
        isCheckAll: !preState.isCheckAll,
        nonReservableTime: currentNonReservable,
      }
    })
  }

  onSelectTime = (event, value, isSelect) => {
    const { nonReservableTime } = this.state
    event.preventDefault()
    const arrayTime = nonReservableTime && nonReservableTime.times ? nonReservableTime.times : []
    if (isSelect) {
      _.remove(arrayTime, valueTime => valueTime === value)
    } else {
      arrayTime.push(value)
    }
    const currentNonReservable = {
      ...nonReservableTime,
      times: arrayTime,
      isAll: nonReservableTime && nonReservableTime.isAll ? nonReservableTime.isAll : false,
    }
    this.setState({
      nonReservableTime: currentNonReservable,
      preTime: arrayTime,
    })
  }

  renderTime = value => {
    const { nonReservableTime, isCheckAll } = this.state
    const isSelect = nonReservableTime
      ? _.find(nonReservableTime.times, valueTime => valueTime === value)
      : false
    const { item, itemText } = styles
    return (
      <div style={styles.timeItemContainer}>
        <a
          href="#"
          style={{ ...item, background: isSelect ? '#F7941C' : '#B0AEAE' }}
          onClick={event => (isCheckAll ? null : this.onSelectTime(event, value, isSelect))}
        >
          <span style={{ ...itemText, color: isCheckAll ? '#C0BEBE' : '#fff' }}>{value}</span>
        </a>
      </div>
    )
  }

  renderTimeTable = (timeValue, index) => {
    const { language } = this.props
    const timeArray = generateTimeRange(timeValue.start, timeValue.end)
    return (
      <>
        <span style={styles.divideText}>{index === 0 ? language.t('am') : language.t('pm')}</span>
        <div style={styles.timeContainer}>
          {timeArray.map(value => {
            return this.renderTime(value)
          })}
        </div>
      </>
    )
  }

  /**
   * Hàm xử lý chọn setting và đóng popup
   */
  onNext = () => {
    const { nonReservableTime, currentDate, onHandleChooseTime } = this.state

    if (onHandleChooseTime) {
      onHandleChooseTime(nonReservableTime, currentDate)
      this.hide()
    }
  }

  /**
   * Hàm xử lý xoá toàn bộ cài đặt
   */
  onResetSetting = () => {
    const currentNonReservable = {
      times: [],
      isAll: false,
    }
    this.setState({
      nonReservableTime: currentNonReservable,
      isCheckAll: false,
    })
  }

  render() {
    const { open, currentDate, isCheckAll, time } = this.state
    const { language } = this.props

    return (
      <Dialog
        id="dialog-booking"
        open={open}
        onClose={this.hide}
        classes={{ paperFullWidth: 'dialog-reservable' }}
        fullWidth
        aria-labelledby="draggable-dialog-title"
      >
        <div style={styles.container}>
          <span style={styles.label}>{language.t('nonReservableSetting')}</span>
          <span style={styles.date}>{moment(currentDate).format('DD/MM/YYYY')}</span>
          {time.length === 1 && (!time[0].end || !time[0].start) ? (
            <p style={styles.empty}>{language.t('emptyTime')}</p>
          ) : (
            <>
              <div style={styles.checkboxContainer}>
                <span style={styles.textAll}>{language.t('cannotBookingAllDay')}</span>
                <Checkbox
                  checked={isCheckAll}
                  onChange={this.checkAll}
                  style={{ marginRight: '10px' }}
                />
              </div>
              {time.length > 0 &&
                time.map((value, index) => {
                  return this.renderTimeTable(value, index)
                })}
            </>
          )}
          <div style={styles.btnAction}>
            <Button
              variant="contained"
              color="inherit"
              style={{
                backgroundColor: '#F7941D',
                color: 'white',
              }}
              // disabled={disableSave}
              onClick={this.onNext}
            >
              {language.t('next')}
            </Button>
            <Button
              variant="contained"
              color="inherit"
              style={{
                backgroundColor: '#ab4e48',
                color: 'white',
                marginLeft: '15px',
              }}
              // disabled={disableSave}
              onClick={this.onResetSetting}
            >
              {language.t('resetSetting')}
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

ReservablePopup.propTypes = {
  language: PropTypes.any,
}

const styles = {
  divideText: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#b3525d',
    marginTop: '20px',
    display: 'block',
  },
  empty: {
    fontSize: '16px',
    textAlign: 'center',
    margin: '20px 0',
  },
  btnAction: {
    display: 'flex',
    flexDirection: 'row',
  },
  btn: {
    backgroundColor: '#F7941D',
    color: '#fff',
    marginTop: 0,
  },
  timeContainer: {
    width: '100%',
    display: 'inline-block',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    padding: '24px',
  },
  label: {
    fontWeight: '500',
    fontSize: '28px',
  },
  textAll: {
    fontSize: '18px',
  },
  timeItemContainer: {
    width: 'calc(1052px / 8)',
    height: '75px',
    float: 'left',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '5px',
  },
  date: {
    fontWeight: '600',
    fontSize: '40px',
    margin: '30px 0',
    display: 'block',
  },
  item: {
    width: '120px',
    height: '60px',
    backgroundColor: '#B0AEAE',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemText: {
    fontSize: '18px',
    color: '#fff',
    fontWeight: '600',
  },
}

export default ReservablePopup

export const convertErrorFormServer = error => {
  if (!error) return null
  try {
    const errorServer = error
    errorServer.response.data.message[0] = getMessageErrorFormServer(errorServer)
    return errorServer
  } catch (e) {
    return error
  }
}

export const getMessageErrorFormServer = error => {
  if (!error) return ''
  try {
    const errorServer = error
    const { status } = errorServer.response

    if (status === 401) {
      return 'error401'
    }
    if (status === 400 || status === 403 || status === 404) {
      return 'error404'
    }

    if (status >= 300 && status < 400) {
      return 'error300'
    }
    if (status >= 400 && status < 500 && status !== 422) {
      return 'error400'
    }
    if (status >= 500) {
      return 'error500'
    }

    return 'error300'
  } catch (e) {
    return 'error400'
  }
}

/* eslint-disable no-nested-ternary */
import React from 'react'
import Axios from 'axios'
import { Button, withStyles, Grid, CardMedia } from '@material-ui/core'
import { connect } from 'react-redux'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import SyncTable from '../../components/Interface/SyncTable'
// import { AUTHEN_TOKEN } from '../../constants/define'
import { mainColor } from '../../constants/style.js'
import { AUTHEN_TOKEN, statusBooking, statusBookingColor } from '../../constants/define'
import { getListBooking, updateBooking } from '../../api/booking'
import DialogCancel from './DialogCancel'

const defineValue = {
  sku: {
    key: 'id',
    displayName: 'bookingList.id',
    default: 0,
  },
  user: {
    key: 'user',
    displayName: 'bookingList.user',
    default: '',
  },
  people: {
    key: 'people',
    displayName: 'bookingList.people',
    default: '',
  },
  date: {
    key: 'date',
    displayName: 'bookingList.date',
    default: '',
  },
  time: {
    key: 'time',
    displayName: 'bookingList.time',
    default: '',
  },
  course: {
    key: 'course',
    displayName: 'bookingList.course',
    default: '',
  },
  note: {
    key: 'note',
    displayName: 'bookingList.note',
    default: '',
  },
  status: {
    key: 'status',
    displayName: 'status',
    default: '',
  },
  action: {
    key: 'status',
    displayName: 'bookingList.action',
    default: '',
  },
}

const formatDataTable = data => {
  return data.map(item => ({
    ...item,
    [defineValue.sku.key]: `${item.id}`,
    [defineValue.user.key]: { image: item.avatar, name: item.name, phone: item.phone },
    [defineValue.people.key]: item.quantity,
    [defineValue.date.key]: item.booked_at,
    [defineValue.time.key]: item.booked_at,
    [defineValue.course.key]: item.courses,
    [defineValue.note.key]: item.note,
    [defineValue.action.key]: item.status,
  }))
}

class Index extends SyncTable {
  columns = [
    { id: defineValue.sku.key, label: defineValue.sku.displayName, minWidth: '70px' },
    {
      id: defineValue.user.key,
      label: defineValue.user.displayName,
      align: 'left',
      format: value => this.renderInfoColumn(value),
      minWidth: '270px',
    },
    {
      id: defineValue.people.key,
      label: defineValue.people.displayName,
      align: 'left',
      minWidth: 30,
    },
    {
      id: defineValue.date.key,
      label: defineValue.date.displayName,
      align: 'left',
      format: value => this.renderDate(value),
      minWidth: 80,
    },
    {
      id: defineValue.time.key,
      label: defineValue.time.displayName,
      align: 'left',
      minWidth: 80,
      format: value => this.renderTime(value),
    },
    {
      id: defineValue.course.key,
      label: defineValue.course.displayName,
      align: 'left',
      format: value => this.renderCourse(value),
      width: 200,
    },
    {
      id: defineValue.note.key,
      label: defineValue.note.displayName,
      align: 'left',
    },
    {
      id: defineValue.status.key,
      label: defineValue.status.displayName,
      align: 'center',
      format: (value, row) => this.renderStatus(value, row),
    },
    {
      id: defineValue.action.key,
      label: defineValue.action.displayName,
      align: 'center',
      format: (value, row) => this.renderAction(value, row),
    },
  ]

  componentDidMount() {
    this.onGetData()
  }

  // @override
  getLanguage = () => {
    const { language } = this.props
    return language
  }

  // @override
  getTitle = () => {
    return this.getLanguage().t('bookingList.title')
  }

  // @override
  getDescription = () => {
    return this.getLanguage().t('bookingList.description')
  }

  // @override
  // func handle popup thoong bao khi thay doi category
  showDialog = (...arg) => {
    this.removeAlert()
    if (this.refDialog.current) this.refDialog.current.show(...arg)
  }

  // func này xử lý xoá alert sau 1 khoảng thời gian nhất định (5000ms)
  timeoutRemoveAlert = () => {
    if (this.timeout) clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.setState({ alertError: '' })
    }, 5000)
  }

  // func để xoá ngay lập tức alert
  removeAlert = () => {
    if (this.timeout) clearTimeout(this.timeout)
    this.setState({ alertError: '' })
  }

  // @override
  renderCreate = () => {
    return (
      <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
        {this.renderAlert()}
      </Grid>
    )
  }

  // @override
  onGetData = async () => {
    if (this.cancelToken) this.cancelToken.cancel('a')
    this.cancelToken = undefined
    this.cancelToken = Axios.CancelToken.source()
    const authen = localStorage.getItem(AUTHEN_TOKEN)
    const { page, limit } = this.state
    const result = await getListBooking({
      authen,
      page,
      limit,
      cancel: this.cancelToken,
    })
    const { isSuccess, data, maxCount } = result

    if (!isSuccess) {
      this.setState({ error: 'somethingWrong', loading: '', loadingTable: false })
    } else {
      this.setState({
        data: formatDataTable(data),
        count: maxCount,
        page,
        loading: false,
        error: data.length <= 0 ? 'bookingList.empty' : '',
        loadingTable: false,
      })
    }
  }

  renderCourse = value => {
    if (Array.isArray(value)) {
      return value.map((valueCourse, index) => {
        return <span>{`${valueCourse.name}${index < valueCourse.length - 1 ? ', ' : ''}`}</span>
      })
    }
    return <div />
  }

  renderDate = value => {
    return <span>{moment(value).format('DD/MM (ddd)')}</span>
  }

  renderTime = value => {
    return <span>{moment(value).format('HH:mm')}</span>
  }

  // render hinh anh mon an
  renderImage = value => {
    const { classes } = this.props
    return (
      <div className={classes.media}>
        <CardMedia className={classes.media} image={value} title="Ảnh minh hoạ" />
      </div>
    )
  }

  /**
   * Render cột chứa thông tin của booking course
   */
  renderInfoColumn = value => {
    const { classes } = this.props
    return (
      <div className={classes.infoContainer}>
        {this.renderImage(value.image)}
        <div className={classes.infoTextContainer}>
          <span className={`${classes.name} three-dot three-line`}>{value.name}</span>
          <span className={classes.phone}>{value.phone}</span>
        </div>
      </div>
    )
  }

  renderStatus = (value, row) => {
    const { classes, language } = this.props
    return (
      <div
        className={classes.done}
        style={{ color: statusBookingColor[value], backgroundColor: '#fff' }}
      >
        <span>
          {value === 'cancel' && row.cancelled_by === 'restaurant'
            ? language.t('statusBooking.cancelByOwner')
            : value === 'cancel' && row.cancelled_by === 'user'
            ? language.t('statusBooking.cancelByCustomer')
            : language.t(statusBooking[value])}
        </span>
      </div>
    )
  }

  renderAction = (value, row) => {
    const { classes, language } = this.props
    return (
      <div className={classes.actionContainer}>
        {value !== 'cancel' && value !== 'expried' ? (
          <>
            {value === 'expired' || value === 'confirm' ? null : (
              <Button
                variant="contained"
                color="inherit"
                style={{
                  backgroundColor: '#F7941D',
                  color: 'white',
                  width: '120px',
                }}
                onClick={() => this.onClickConfirm(row, 'confirm')}
                // disabled={disableSave}
              >
                {language.t('bookingAction.confirm')}
              </Button>
            )}
            {value === 'expired' ? null : (
              <Button
                variant="contained"
                color="inherit"
                style={{
                  backgroundColor: '#f50057',
                  color: 'white',
                  marginTop: '15px',
                  width: '120px',
                }}
                onClick={() => this.onClickEdit(row, 'edit')}
                // disabled={disableSave}
              >
                {language.t('edit')}
              </Button>
            )}
            {value !== 'expired' ? (
              <Button
                variant="contained"
                color="inherit"
                style={{
                  backgroundColor: '#f50057',
                  color: 'white',
                  marginTop: '15px',
                  width: '120px',
                }}
                onClick={() => this.onClickCancelBooking(row)}
                // disabled={disableSave}
              >
                {language.t('bookingAction.cancel')}
              </Button>
            ) : null}
          </>
        ) : null}
      </div>
    )
  }

  // func khi click vao btn delete
  // hien thi popup loading, hỏi lại
  onClickCancel = (row, status) => {
    this.showDialog({
      action: () => this.onConfirmAction(row, status),
      title: 'bookingAction.dialogCancel.label',
      content: 'bookingAction.dialogCancel.title',
      contentNoData: 'bookingAction.dialogCancel.title',
    })
    this.setDataDialog([])
  }

  /**
   * On click cancel booking button
   * @param {*} row
   */
  onClickCancelBooking = row => {
    if (this.dialogCancel) {
      this.dialogCancel.current.onOpenDialog(row.id)
    }
  }

  // func khi click vao btn edit
  // di chuyen den man hinh edit
  onClickEdit = row => {
    const { history } = this.props
    history.push(`/admin/booking/edit/${row.id}`)
  }

  // func khi click vao btn delete
  // hien thi popup loading, hỏi lại
  onClickConfirm = (row, status) => {
    this.showDialog({
      action: () => this.onConfirmAction(row, status),
      title: 'bookingAction.dialogConfirm.label',
      content: 'bookingAction.dialogConfirm.title',
      contentNoData: 'bookingAction.dialogConfirm.title',
    })
    this.setDataDialog([])
  }

  // func goi api de xoa Course
  onConfirmAction = (row, status) => {
    const authenToken = localStorage.getItem(AUTHEN_TOKEN)
    const input = {
      id: row.id,
      statusBooking: status,
      authenToken,
    }

    updateBooking(input).then(result => {
      if (result.isSuccess) {
        const { data } = this.state
        const newData = data.map(item => {
          if (item.id === row.id) {
            return { ...item, [defineValue.action.key]: status }
          }
          return item
        })
        this.setState({ data: newData })
      } else {
        this.setState({ loadingTable: false, alertError: 'somethingWrong' })
      }

      this.hideDialog()
      this.timeoutRemoveAlert()
    })
  }

  /**
   * Confirm Cancel booking
   * @param {*} bookingId
   * @param {*} isSuccess
   */
  onConfirmCancelBooking = (bookingId, isSuccess) => {
    if (isSuccess) {
      const { data } = this.state
      const newData = data.map(item => {
        if (item.id === bookingId) {
          return {
            ...item,
            status: 'cancel',
            [defineValue.action.key]: 'cancel',
            cancelled_by: 'restaurant',
          }
        }
        return item
      })
      this.setState({ data: newData })
    } else {
      this.setState({ loadingTable: false, alertError: 'somethingWrong' })
      this.timeoutRemoveAlert()
    }
  }

  renderDialogCancel = () => {
    this.dialogCancel = React.createRef()
    return (
      <DialogCancel
        ref={this.dialogCancel}
        language={this.getLanguage()}
        onConfirmCancelBooking={this.onConfirmCancelBooking}
      />
    )
  }

  // @override
  renderAlert = () => {
    const { alertError } = this.state
    if (!alertError) return <div />
    return (
      <Grid
        item
        md={8}
        xs={12}
        style={{
          marginBottom: 30,
        }}
      >
        <Alert severity="error">{this.getLanguage().t(alertError)}</Alert>
      </Grid>
    )
  }

  render() {
    return (
      <>
        {this.renderCreate()}
        {this.renderContainer()}
        {this.renderDialog()}
        {this.renderDialogCancel()}
      </>
    )
  }
}

const styles = {
  buttonfilter: {
    marginRight: '8px',
    backgroundColor: mainColor,
  },
  media: {
    height: '120px',
    width: '120px',
    backgroundSize: 'contain',
    borderRadius: '8px',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '120px',
  },
  infoTextContainer: {
    display: 'flex',
    padding: '10px 0',
    marginLeft: '20px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  name: {
    fontWeight: '600',
    fontSize: '22px',
    width: '200px',
  },
  phone: {
    fontSize: '18px',
    color: '#000',
    marginTop: '10px',
  },
  price: {
    fontSize: '18px',
    color: '#E37575',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  priorityContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  done: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '120px',
    borderRadius: '8px',
    color: '#fff',
    backgroundColor: '#5385AA',
  },
}

const mapStateToProps = state => {
  return {
    language: state.languageReducer.language,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Index))

import React from 'react'
import Axios from 'axios'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'
import SyncTable from '../../../components/Interface/SyncTable'
// import { AUTHEN_TOKEN } from '../../constants/define'
import { mainColor } from '../../../constants/style.js'
import { AUTHEN_TOKEN } from '../../../constants/define'
import { getListNotification } from '../../../api'
import { getTextFromBacket } from '../../../commons'

const defineValue = {
  sku: {
    key: 'id',
    displayName: 'notificationBooking.id',
    default: 0,
  },
  title: {
    key: 'title',
    displayName: 'notificationBooking.title',
    default: '',
  },
  id_booking: {
    key: 'idBooking',
    displayName: 'notificationBooking.idBooking',
    default: '',
  },
  time: {
    key: 'time',
    displayName: 'notificationBooking.time',
    default: '',
  },
}

const formatDataTable = data => {
  return data.map(item => ({
    ...item,
    [defineValue.sku.key]: `${item.id}`,
    [defineValue.title.key]: item.title,
    [defineValue.id_booking.key]: item.booking_id,
    [defineValue.time.key]: item.created_at,
    color: item.read ? '#eee' : '#fff',
  }))
}

class Index extends SyncTable {
  columns = [
    { id: defineValue.sku.key, label: defineValue.sku.displayName, minWidth: 50 },
    {
      id: defineValue.title.key,
      label: defineValue.title.displayName,
      align: 'left',
      format: value => this.renderTitle(value),
    },
    {
      id: defineValue.id_booking.key,
      label: defineValue.id_booking.displayName,
      align: 'left',
    },
    {
      id: defineValue.time.key,
      label: defineValue.time.displayName,
      align: 'left',
      format: value => this.renderDate(value),
    },
  ]

  componentDidMount() {
    this.onGetData()
  }

  // @override
  getLanguage = () => {
    const { language } = this.props
    return language
  }

  // @override
  getTitle = () => {
    return this.getLanguage().t('notificationBooking.label')
  }

  // @override
  getDescription = () => {
    return this.getLanguage().t('notificationBooking.description')
  }

  // @override
  // func handle popup thoong bao khi thay doi category
  showDialog = (...arg) => {
    this.removeAlert()
    if (this.refDialog.current) this.refDialog.current.show(...arg)
  }

  // func này xử lý xoá alert sau 1 khoảng thời gian nhất định (5000ms)
  timeoutRemoveAlert = () => {
    if (this.timeout) clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.setState({ alertError: '' })
    }, 5000)
  }

  // func để xoá ngay lập tức alert
  removeAlert = () => {
    if (this.timeout) clearTimeout(this.timeout)
    this.setState({ alertError: '' })
  }

  // @override
  onGetData = async () => {
    if (this.cancelToken) this.cancelToken.cancel('a')
    this.cancelToken = undefined
    this.cancelToken = Axios.CancelToken.source()
    const authen = localStorage.getItem(AUTHEN_TOKEN)
    const { page, limit } = this.state
    try {
      const result = await getListNotification({
        authen,
        page,
        limit,
        cancel: this.cancelToken,
      })
      const { isSuccess, data, maxCount } = result

      if (!isSuccess) {
        this.setState({ error: 'somethingWrong', loading: '', loadingTable: false })
      } else {
        this.setState({
          data: formatDataTable(data),
          count: maxCount,
          page,
          loading: false,
          error: data.length <= 0 ? 'notificationBooking.empty' : '',
          loadingTable: false,
        })
      }
    } catch (e) {
      console.warn(e)
    }
  }

  renderTitle = value => {
    const content = getTextFromBacket(value || '')

    return (
      <div>
        <span style={{ fontWeight: 'bold' }}>{content.nameItem} </span>
        <span>{content.contentItem}</span>
      </div>
    )
  }

  renderDate = value => {
    return <span>{moment(value).format('DD-MM-YYYY HH:mm')}</span>
  }

  render() {
    return (
      <>
        {this.renderContainer()}
        {this.renderDialog()}
      </>
    )
  }
}

const styles = {
  buttonfilter: {
    marginRight: '8px',
    backgroundColor: mainColor,
  },
  media: {
    height: '120px',
    width: '120px',
    backgroundSize: 'contain',
    borderRadius: '8px',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '120px',
  },
  infoTextContainer: {
    display: 'flex',
    padding: '10px 0',
    marginLeft: '20px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  name: {
    fontWeight: '600',
    fontSize: '22px',
  },
  phone: {
    fontSize: '18px',
    color: '#000',
    marginTop: '10px',
  },
  price: {
    fontSize: '18px',
    color: '#E37575',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  priorityContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  done: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '120px',
    borderRadius: '8px',
    color: '#fff',
    backgroundColor: '#5385AA',
  },
}

const mapStateToProps = state => {
  return {
    language: state.languageReducer.language,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Index))

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Grid, Card, makeStyles, Button } from '@material-ui/core'
import Table from '../Table/Table'
import { I18n } from '../../config'
import CardHeader from '../Card/CardHeader'
import { mainColor } from '../../constants/style.js'
import Popup from './Popup'

// không được phép sửa đổi các chức năng có trong này:/
// chỉ sửa đổi giao diện: nếu cần thiết

/**
 * Component mẫu dùng cho việc hiển thị các danh sách trên web
 * Bao gồm
 * state :{
 *  data
 *  loading
 *  error
 *  page
 *  count
 * }
 *
 * func:
 * changePage
 * changePerRowPage
 * onGetData
 *
 * Bao gồm hiển thị
 * renderTable
 * renderLoading
 * renderError
 * renderHeader
 *
 * require
 * columns: []
 *
 */

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  buttonfilter: {
    backgroundColor: mainColor,
  },
  buttonCreate: {
    // width: '100%',
    display: 'flex',
    // justifyContent: 'flex-end',
    marginBottom: 30,
  },
}

const HeaderList = ({ title, description }) => {
  const classes = makeStyles(styles)()
  return (
    <Grid item md={8}>
      <h4 className={classes.cardTitleWhite}>{title}</h4>
      <p className={classes.cardCategoryWhite}>{description}</p>
    </Grid>
  )
}

HeaderList.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

const CreateButton = ({ title, onClick }) => {
  const classes = makeStyles(styles)()
  return (
    <div className={classes.buttonCreate}>
      <Button
        variant="contained"
        color="primary"
        className={classes.buttonfilter}
        style={{ width: '100%' }}
        onClick={onClick}
      >
        {title}
      </Button>
    </div>
  )
}

CreateButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
}

class SyncTable extends Component {
  columns = []

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      loadingTable: false,
      error: '',
      data: [],
      page: 1,
      count: 10,
      limit: 10,
      alertError: '',
    }

    this.refDialog = React.createRef()
  }

  // sử dụng cho multiple language
  // default là I18n
  getLanguage = () => {
    return I18n
  }

  getAlertError = () => {
    const { alertError } = this.state
    return alertError
  }

  // func get
  getTitle = () => {
    return ''
  }

  getDescription = () => {
    return ''
  }

  getTitleCreate = () => {
    return ''
  }

  // func handle popup
  showDialog = (...arg) => {
    if (this.refDialog.current) this.refDialog.current.show(...arg)
  }

  hideDialog = () => {
    if (this.refDialog.current) this.refDialog.current.hide()
  }

  setDataDialog = data => {
    if (this.refDialog.current) this.refDialog.current.setData(data)
  }

  // func dùng cho override
  // sử dụng ngay sau khi didmount và sau khi thay đổi page
  onGetData = () => {}

  // func khi click vao btn create
  onClickButtonCreate = () => {}

  // 2 func xử lý việc thay đổi page
  onChangePage = (event, newPage) => {
    this.setState({ page: newPage + 1, loadingTable: true }, () => {
      this.onGetData()
    })
  }

  onChangeRowsPerPage = event => {
    this.setState({ page: 1, limit: +event.target.value, loadingTable: true }, () => {
      this.onGetData()
    })
  }

  // render active
  renderHeaderRight = () => {}

  // render Header
  renderHeader = () => {
    return (
      <CardHeader plain color="primary">
        <Grid container>
          <HeaderList title={this.getTitle()} description={this.getDescription()} />
          {this.renderHeaderRight()}
        </Grid>
      </CardHeader>
    )
  }

  // func render dialog khi click vào btn xoá
  renderDialog = () => {
    return <Popup ref={this.refDialog} language={this.getLanguage()} />
  }

  // render alert lỗi
  renderAlert = () => {
    return <div />
  }

  // render btn create
  renderCreate = () => {
    return (
      <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
        {this.renderAlert()}
        <Grid item md={2} xs={5}>
          <CreateButton title={this.getTitleCreate()} onClick={this.onClickButtonCreate} />
        </Grid>
      </Grid>
    )
  }

  // func hiển thị danh sách dưới dạng bảng
  renderList = () => {
    const { data, limit, count, page, loadingTable } = this.state
    return (
      <Table
        columns={this.columns}
        data={data}
        loading={loadingTable}
        rowsPerPage={limit}
        maxCount={count}
        page={page > 0 ? page - 1 : 1}
        handleChangePage={this.onChangePage}
        handleChangeRowsPerPage={this.onChangeRowsPerPage}
        language={this.getLanguage()}
      />
    )
  }
  // func render trạng thái loading ban đầu khi lấy dữ liệu

  renderLoading = () => {
    return (
      <div style={{ flex: 1, justifyContent: 'center', display: 'flex', marginTop: 50 }}>
        <CircularProgress size={60} />
      </div>
    )
  }

  // func render message lỗi
  renderError = error => {
    return (
      <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
        <p style={{ fontSize: '20px', ...(error === 'somethingWrong' ? { color: 'red' } : null) }}>
          {this.getLanguage().t(error)}
        </p>
      </div>
    )
  }

  renderContent = () => {
    const { loading } = this.state
    if (loading) return this.renderLoading()

    const { data, error } = this.state

    if (!Array.isArray(data) || (data.length <= 0 && error)) return this.renderError(error)
    return (
      <>
        <Card style={{ padding: 12, marginTop: 24 }}>{this.renderList()}</Card>
      </>
    )
  }

  renderContainer = () => {
    return (
      <>
        {this.renderHeader()}
        {this.renderContent()}
      </>
    )
  }

  render() {
    return this.renderContainer()
  }
}

export default SyncTable

/* eslint-disable no-useless-escape */
import moment from 'moment'
import _ from 'lodash'
import localization from 'moment/locale/en-gb'
import { HH_mm_DD_MM_YYYY, hh_mm } from '../constants/define'
/**
 *
 * @param {*} time mốc thời gian được check "HH:mm"
 * @param {*} beforeTime khoảng thơi gian được phép đặt
 */
export const checkTimeWithBookingBefore = (time, beforeTime) => {
  if (!beforeTime) {
    // cần check với thời gian hiện tại
    if (moment(time, HH_mm_DD_MM_YYYY) > moment()) return true
  }

  if (moment(time, HH_mm_DD_MM_YYYY) > moment().add(beforeTime, 'hour')) return true
  return false
}

/**
 *
 * @param {*} time thời gian, nên là moment
 * @param {*} bookingDay string or mảng chứa các ngày ko được phép booking
 */
export const checkArrayDayIncludeTime = (time, bookingDay) => {
  try {
    // kiểm tra xem @param bookingDay có phải là mảng ko, nếu ko phải thì parse qua mảng
    let arrayBookingDay = bookingDay
    if (typeof arrayBookingDay === 'string') {
      arrayBookingDay = JSON.parse(
        bookingDay
          .replace(/\'/g, '"')
          .replace(/^"/, "'")
          .replace(/"$/, "'")
      )
    }

    // nếu parse qua vẫn ko phải là mảng thì mặc định trả về true
    if (!Array.isArray(arrayBookingDay)) return false

    // format lại time
    let timeMoment = time
    if (!moment.isMoment(time)) {
      timeMoment = moment(time)
    }

    // lấy ra thứ của thời gian
    const weekDay = timeMoment
      .locale('en', localization)
      .format('ddd')
      .toLowerCase()

    // nếu mảng kia chứa thứ hiện tại thì return false

    if (arrayBookingDay.includes(weekDay)) return true

    return false
  } catch (error) {
    return false
  }
}

/**
 *
 * @param {*} time thoi gian can check
 * @param {*} specialDay object thong tin ngay
 * bao gom {
 *  all_day
 *  time
 * }
 */
export const checkTimeWithSpecialDay = (_time, specialDay) => {
  if (!specialDay) return true

  const { isAll, times } = specialDay

  if (isAll) return false

  if (!Array.isArray(times)) return true

  if (times.includes(_time)) return false

  return true
}

/**
 *
 * @param {*} objTime  {
 *  time
 *  day
 * }
 * dựa vào object để lấy ra thông tin chính xác thời gian mở cửa
 */
export const getOpenTime = (_time, objTime) => {
  if (!objTime) return undefined

  const { time, day } = objTime

  if (!Array.isArray(time) || time.length < 2 || !Array.isArray(day)) return undefined
  // format lại time
  let timeMoment = _time
  if (!moment.isMoment(_time)) {
    timeMoment = moment(_time)
  }

  // lấy ra thứ của thời gian
  const weekDay = timeMoment
    .locale('en', localization)
    .format('ddd')
    .toLowerCase()
  if (day.includes(weekDay)) return `${time[0]}-${time[1]}`

  return undefined
}

/**
 *
 * @param {} range
 * func generate mảng các mốc thời gian được chọn
 * chỉ lấy các mốc thời gian  % 15 = 0
 */
export const generateRangeTime = range => {
  const arrayTime = []
  if (Array.isArray(range)) {
    range.forEach(el => {
      const startTime = `${el}`.split('-')[0]
      let endTime = `${el}`.split('-')[1]

      // tính toán để lấy ra mốc thời gian nhỏ nhất có thể % 15 = 0
      let reminderStartTime = moment(startTime, hh_mm)
      // .add(15 - (moment(startTime, hh_mm).minute() % 15), 'minute')
      // .format(hh_mm)

      /**
       * check thời gian cuối cùng của ngày `23:45`
       * vì khoảng thời gian này + 15p sẽ sang ngày mới => sẽ dẫn đến lỗi
       */
      if (moment(endTime, hh_mm) >= moment('23:45', hh_mm)) {
        endTime = moment(endTime, hh_mm)
          .subtract(15, 'minute')
          .format(hh_mm)
        arrayTime.push('23:45')
      }

      while (moment(reminderStartTime, hh_mm) <= moment(endTime, hh_mm)) {
        arrayTime.push(moment(reminderStartTime, hh_mm).format(hh_mm))
        reminderStartTime = moment(reminderStartTime, hh_mm)
          .add(15, 'minute')
          .format(hh_mm)
      }
    })
  }

  return _.uniq(_.sortBy(arrayTime))
}

// generate mảng số người đc phép đặt
export const generateRangeNumber = (min, max) => {
  const arrayNumber = []
  for (let index = min; index <= max; index += 1) {
    arrayNumber.push({
      label: `${index}`,
      key: index,
      value: index,
    })
  }
  return arrayNumber
}

/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

const resultTitle = {
  error: 'orderResult.error',
  error1: 'orderResult.error1',
  success: 'orderResult.success',
  timeout: 'orderResult.timeout',
}

class Index extends PureComponent {
  render() {
    const { language, message, isErrorGetData, isShowMessage } = this.props
    // console.log('MessageResult -> message', message)
    // Khi message là a tức là request lên axios bị cancel, nên sẽ không hiển thị message nữa
    if (typeof message !== 'string' || message.length <= 0 || message === 'a') return null
    if (isErrorGetData) {
      if (isShowMessage) {
        return (
          <div>
            {message.split('/').map((i, index) => {
              return (
                <p key={`${i}-${index}`} style={{ fontSize: '20px', textAlign: 'center' }}>
                  {i}
                </p>
              )
            })}
          </div>
        )
      }
      return (
        <div>
          <p style={{ fontSize: '20px', textAlign: 'center', color: 'red' }}>
            {language.t('somethingWrong')}
          </p>
        </div>
      )
    }

    const arr = message.split('/')
    return (
      <div
        style={{
          backgroundColor:
            message === language.t(resultTitle.error) ||
            message === language.t(resultTitle.error1) ||
            message === language.t(resultTitle.timeout) ||
            message === language.t('orderResult.delivery')
              ? 'red'
              : 'green',
          borderRadius: '5px',
          padding: '5px 10px',
          marginBottom: '10px',
        }}
      >
        {arr.map((i, index) => {
          return (
            <p key={`${i}-${index}`} style={{ color: 'white', marginLeft: '20px', margin: '6px' }}>
              {i}
            </p>
          )
        })}
      </div>
    )
  }
}

Index.propTypes = {
  language: PropTypes.any,
  message: PropTypes.any,
  isErrorGetData: PropTypes.bool,
  isShowMessage: PropTypes.bool,
}

export default Index

const styles = {
  viewFlag: {
    padding: '5px',
    backgroundColor: '#fff',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #636363',
  },
  imgFlag: {
    width: '30px',
    height: '20px',
  },
  popupView: {
    padding: '5px 0px',
    backgroundColor: '#fff',
    borderRadius: 4,
    border: '1px solid #636363',
    display: 'flex',
    flexDirection: 'column',
  },
}

export default styles

import config from '../config_env'

/*
 * api domain constants
 */
export const baseURL = config.baseUrl

export const version = 'api/v101/'

export const TIMEOUT = 30000

/** ************** API USER  ************** */
export const loginApi = 'restaurants/sign_in'
export const signOutApi = 'restaurants/sign_out'

/** ============== API PROFILE ============= */
export const profileApi = `${version}restaurants/booking_settings`
export const updateProfileApi = `${version}restaurants/booking_settings/update`
export const getListUser = `${version}restaurants/search_users`

/** ============== API COURSE ============= */
export const listCourseApi = `${version}restaurants/courses`

/** ============== API BOOKING ============= */
export const listBookingApi = `${version}restaurants/bookings`
export const apiBookingUpdate = id => `${version}restaurants/bookings/${id}`

// notification
export const listNotificationApi = `${version}restaurants/booking_notifications`
export const readCountNotificationApi = `${version}restaurants/booking_notification_counter`

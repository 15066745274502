/* eslint-disable no-undef */
import request from './request'
import { updateProfileApi, profileApi } from './config'
import { getMessageErrorFormServer } from '../commons'

export const getProfileApi = async authenToken => {
  try {
    const { data } = await request(authenToken).get(`${profileApi}`)
    // console.log({ data })
    return {
      status: 'success',
      data: data.data,
    }
  } catch (error) {
    return {
      status: 'failed',
      message: getMessageErrorFormServer(error),
    }
  }
}

export const updateProfile = async ({ authen, ...props }) => {
  try {
    const { data } = await request(authen).put(`${updateProfileApi}`, props)
    // console.log({ data })
    return {
      isSuccess: true,
      status: 'success',
      data: data.data,
    }
  } catch (error) {
    return {
      isSuccess: false,
      status: 'failed',
      message: getMessageErrorFormServer(error),
    }
  }
}

export const test = async () => {}

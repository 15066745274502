import React from 'react'
import Axios from 'axios'
import { Button, withStyles, Grid, CardMedia, Checkbox } from '@material-ui/core'
import { connect } from 'react-redux'
import { Alert } from '@material-ui/lab'
import SyncTable from '../../components/Interface/SyncTable'
import { formatMoney } from '../../commons/momey'
// import { AUTHEN_TOKEN } from '../../constants/define'
import { mainColor } from '../../constants/style.js'
import { AUTHEN_TOKEN } from '../../constants/define'
import { getListCourse, editCourse, deleteCourse } from '../../api/course'

const defineValue = {
  sku: {
    key: 'id',
    displayName: 'bookingCourse.id',
    default: 0,
  },
  info: {
    key: 'info',
    displayName: 'bookingCourse.info',
    default: '',
  },
  detail: {
    key: 'detail',
    displayName: 'bookingCourse.detail',
    default: '',
  },
  action: {
    key: 'action',
    displayName: 'bookingCourse.action',
    default: '',
  },
}

const formatDataTable = data => {
  return data.map(item => ({
    ...item,
    [defineValue.sku.key]: `${item.id}`,
    [defineValue.info.key]: { image: item.image, name: item.name, price: item.price },
    [defineValue.detail.key]: item.content,
    [defineValue.action.key]: item.popular,
  }))
}

class Index extends SyncTable {
  columns = [
    { id: defineValue.sku.key, label: defineValue.sku.displayName, minWidth: 100 },
    {
      id: defineValue.info.key,
      label: defineValue.info.displayName,
      minWidth: 320,
      align: 'left',
      format: value => this.renderInfoColumn(value),
    },
    {
      id: defineValue.detail.key,
      label: defineValue.detail.displayName,
      minWidth: 180,
      align: 'left',
      //   format: value => console.log(value),
    },
    {
      id: defineValue.action.key,
      label: defineValue.action.displayName,
      minWidth: 160,
      align: 'center',
      format: (value, row) => this.renderAction(value, row),
    },
  ]

  componentDidMount() {
    this.onGetData()
  }

  // @override
  getLanguage = () => {
    const { language } = this.props
    return language
  }

  // @override
  getTitleCreate = () => {
    return this.getLanguage().t('bookingCourse.create')
  }

  // @override
  getTitle = () => {
    return this.getLanguage().t('bookingCourseList')
  }

  // @override
  getDescription = () => {
    return this.getLanguage().t('bookingCourseDescription')
  }

  // @override
  // func handle popup thoong bao khi thay doi category
  showDialog = (...arg) => {
    this.removeAlert()
    if (this.refDialog.current) this.refDialog.current.show(...arg)
  }

  // func này xử lý xoá alert sau 1 khoảng thời gian nhất định (5000ms)
  timeoutRemoveAlert = () => {
    if (this.timeout) clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.setState({ alertError: '' })
    }, 5000)
  }

  // func để xoá ngay lập tức alert
  removeAlert = () => {
    if (this.timeout) clearTimeout(this.timeout)
    this.setState({ alertError: '' })
  }

  // @override
  onGetData = async () => {
    if (this.cancelToken) this.cancelToken.cancel('a')
    this.cancelToken = undefined
    this.cancelToken = Axios.CancelToken.source()
    const authen = localStorage.getItem(AUTHEN_TOKEN)
    const { page, limit } = this.state
    try {
      const result = await getListCourse({
        authen,
        page,
        limit,
        cancel: this.cancelToken,
      })
      const { isSuccess, data, maxCount } = result
      if (!isSuccess) {
        this.setState({ error: 'somethingWrong', loading: '', loadingTable: false })
      } else {
        this.setState({
          data: formatDataTable(data),
          count: maxCount,
          page,
          loading: false,
          error: data.length <= 0 ? 'courseEmpty' : '',
          loadingTable: false,
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  // @override
  onClickButtonCreate = () => {
    const { history } = this.props
    history.push('course/create')
  }

  // fucn khi click vao btn chinh sua
  onClickEdit = row => {
    const { history } = this.props
    history.push(`course/edit/${row.id}`)
  }

  // render hinh anh mon an
  renderImage = value => {
    const { classes } = this.props
    return (
      <div className={classes.media}>
        <CardMedia className={classes.media} image={value} title="Ảnh minh hoạ" />
      </div>
    )
  }

  /**
   * Render cột chứa thông tin của booking course
   */
  renderInfoColumn = value => {
    const { classes } = this.props
    return (
      <div className={classes.infoContainer}>
        {this.renderImage(value.image)}
        <div className={classes.infoTextContainer}>
          <span className={`${classes.name} wrap-name`}>{value.name}</span>
          <span className={classes.price}>{`${formatMoney(value.price)} VND / 1 person`}</span>
        </div>
      </div>
    )
  }

  renderAction = (value, row) => {
    const { classes, language } = this.props
    return (
      <div className={classes.actionContainer}>
        <div style={{ marginLeft: '-35px' }} className={classes.priorityContainer}>
          <Checkbox checked={value} onChange={event => this.onChangePriority(event, row)} />
          <span>{language.t('popular')}</span>
        </div>
        <Button
          variant="contained"
          color="inherit"
          style={{
            backgroundColor: '#F7941D',
            color: 'white',
            width: '120px',
          }}
          onClick={() => this.onClickEdit(row)}
          // disabled={disableSave}
        >
          {language.t('edit')}
        </Button>
        <Button
          variant="contained"
          color="inherit"
          style={{
            backgroundColor: '#f50057',
            color: 'white',
            marginTop: '15px',
            width: '120px',
          }}
          onClick={() => this.onClickDelete(row)}
          // disabled={disableSave}
        >
          {language.t('delete')}
        </Button>
      </div>
    )
  }

  // func khi click vao btn delete
  // hien thi popup loading, hỏi lại
  onClickDelete = row => {
    this.showDialog({
      action: () => this.onDelete(row),
      title: 'bookingCourse.delete',
      content: 'bookingCourse.confirmDelete',
      contentNoData: 'bookingCourse.confirmDelete',
    })
    this.setDataDialog([])
  }

  // func goi api de xoa Course
  onDelete = row => {
    const authenToken = localStorage.getItem(AUTHEN_TOKEN)

    const input = {
      id: row.id,
      authenToken,
    }
    deleteCourse(input).then(result => {
      if (result.isSuccess) {
        const { data, count } = this.state
        const newData = data.filter(item => item.id !== row.id)
        this.setState({ data: newData, loadingTable: false, count: count - 1 }, () => {
          const { page } = this.state
          // sau khi set state mới: nếu hết bảng này sẽ check xem có phải page 1 không
          // nếu không phải page 1 sẽ back về page trước và gọi lại data
          if (newData.length <= 0 && page > 1) {
            this.setState({ loadingTable: true, page: page - 1 }, () => {
              this.onGetData()
            })
          }
        })
      } else {
        this.setState({ loadingTable: false, alertError: 'somethingWrong' })
      }
      this.hideDialog()
      this.timeoutRemoveAlert()
    })
  }

  onChangePriority = async (event, row) => {
    const { checked } = event.target

    this.showDialog({ action: () => {}, title: '', content: '' })

    this.onActivePriority(row, checked)
  }

  onActivePriority = (row, checked) => {
    const authenToken = localStorage.getItem(AUTHEN_TOKEN)
    const input = {
      id: row.id,
      statusPopular: checked,
      authenToken,
    }

    editCourse(input).then(result => {
      setTimeout(() => {
        if (result.isSuccess) {
          const { data } = this.state
          const newData = data.map(item => {
            if (item.id === row.id) {
              return { ...item, [defineValue.action.key]: checked }
            }
            return item
          })
          this.setState({ data: newData })
        } else {
          this.setState({
            loadingTable: false,
            alertError: 'somethingWrong',
          })
        }

        this.hideDialog()
        this.timeoutRemoveAlert()
      }, 500)
    })
  }

  // @override
  renderAlert = () => {
    const { alertError } = this.state
    if (!alertError) return <div />
    return (
      <Grid
        item
        md={8}
        xs={12}
        style={{
          marginBottom: 30,
        }}
      >
        <Alert severity="error">{this.getLanguage().t(alertError)}</Alert>
      </Grid>
    )
  }

  render() {
    return (
      <>
        {this.renderCreate()}
        {this.renderContainer()}
        {this.renderDialog()}
      </>
    )
  }
}

const styles = {
  buttonfilter: {
    marginRight: '8px',
    backgroundColor: mainColor,
  },
  media: {
    height: '120px',
    width: '120px',
    backgroundSize: 'contain',
    borderRadius: '8px',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '120px',
  },
  infoTextContainer: {
    display: 'flex',
    padding: '10px 0',
    marginLeft: '20px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  name: {
    fontWeight: '600',
    fontSize: '16px',
  },
  price: {
    fontSize: '18px',
    color: '#E37575',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  priorityContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}

const mapStateToProps = state => {
  return {
    language: state.languageReducer.language,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Index))

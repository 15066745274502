/* eslint-disable no-useless-escape */
export const AUTHEN_TOKEN = 'authenToken_capichi_booking_crm'
export const USER_ID = 'USER_ID'
export const DEVICE_TOKEN = 'deviceToken_capichi_booking_crm'
export const TYPE_NOTIFI = 'notification'

export const LOCALE = 'LOCALE'

export const languageKey = {
  vi: 'vi',
  ja: 'ja',
  ko: 'ko',
  en: 'en',
}

export const vatPer = 0.1
export const formatTimepicker = 'HH:mm DD-MM-YYYY'
export const formatTime = 'HH:mm'
export const lengthTime = 5
export const DD_MM_YYYY = 'DD-MM-YYYY'
export const YYYY_MM_DD = 'YYYY-MM-DD'
export const formatDateTime = 'DD-MM-YYYY HH:mm'
export const HH_mm_DD_MM_YYYY = 'HH:mm, DD-MM-YYYY'
export const hh_mm = 'HH:mm'

export const regexSpecial = /[!@#$%^&*(),.?":{}_=`'~|<>\-/\\\[\]\+]/g
export const regexEmoji = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu

export const languageList = {
  vi: 2,
  ja: 1,
  ko: 4,
}

export const dayList = {
  mon: 'day.mon',
  tue: 'day.tue',
  wed: 'day.wed',
  thu: 'day.thu',
  fri: 'day.fri',
  sat: 'day.sat',
  sun: 'day.sun',
}

export const statusBooking = {
  pending: 'statusBooking.pending',
  confirm: 'statusBooking.confirm',
  cancel: 'statusBooking.cancel',
  expired: 'statusBooking.expired',
}

export const statusBookingColor = {
  pending: '#8a3200',
  confirm: '#1840CA',
  cancel: '#FF3838',
  expired: '#F7941D',
}
export const maxLengthPrice = 10

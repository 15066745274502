import React from 'react'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import { CircularProgress } from '@material-ui/core'

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

// Component này dùng riêng cho bảng:
// Chưc năng hoạt động: hiển thị được loading
// hiển thị danh sách hoặc content theo mong muốn

// method:
/**
 * show
 * hide
 * setData (danh sách mong muốn)
 */
export default class Popup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      title: '',
      content: '',
      loading: true,
      contentNoData: '',
    }

    this.action = () => {}
  }

  // hiển thị
  show = ({ action, title, content, contentNoData }) => {
    this.setState({ open: true, title, content, contentNoData, loading: true })
    this.action = action
  }

  // ẩn popup
  hide = () => {
    this.setState({ open: false })
    this.action = () => {}
  }

  // func set data để hiển thị danh sách món ăn
  setData = data => {
    this.setState({ dataFood: data, loading: false })
  }

  handleAction = () => {
    this.setState({ loading: true }, () => {
      if (this.action) this.action()
      this.action = () => {}
    })
  }

  renderRow = ({ item }) => {
    const { renderRow } = this.props
    if (renderRow) return renderRow({ item })

    return (
      <p key={item.id}>
        {item.id} - {item.name}
      </p>
    )
  }

  // render list food
  renderList = () => {
    const { dataFood } = this.state
    if (dataFood.length <= 0) return null

    return <>{dataFood.map(item => this.renderRow({ item }))}</>
  }

  // render loading
  renderLoading = () => {
    return (
      <DialogContent>
        <CircularProgress size={60} />
      </DialogContent>
    )
  }

  // render content cua popup
  renderContent = () => {
    const { loading, title, content, dataFood, contentNoData } = this.state
    const { language } = this.props

    if (loading) return this.renderLoading()
    return (
      <>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {language.t(title)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language.t(dataFood.length > 0 ? content : contentNoData)}
          </DialogContentText>
          {this.renderList()}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.hide} color="primary">
            {language.t('cancel')}
          </Button>
          <Button onClick={this.handleAction} color="primary">
            {language.t('ok')}
          </Button>
        </DialogActions>
      </>
    )
  }

  render() {
    const { open } = this.state
    return (
      <Dialog
        open={open}
        // onClose={this.hide}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        {this.renderContent()}
      </Dialog>
    )
  }
}

Popup.propTypes = {
  language: PropTypes.any.isRequired,
  renderRow: PropTypes.func,
}

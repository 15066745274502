import { SET_LANGUAGE } from '../actions/actionTypes'

export const setLanguage = language => {
  return {
    type: SET_LANGUAGE,
    language,
  }
}

export const test = () => {}

export default {
  pageTitle: 'Capichi Booking',
  storeInfo: 'レストラン情報',
  name: '店名',
  hello: 'こんにちは',
  validatePassword: 'パスワードは8文字以上で、25文字を超えることはできません。',
  validateField: 'すべてのフィールドに記入してください',
  email: 'メールアドレス',
  password: 'パスワード',
  profileName: 'プロフィール',
  logOut: 'ログアウト',
  signIn: 'サインイン',
  address: '住所',
  status: 'アクティブ',
  phoneNumber: '電話番号',
  shippingFee: '配送料金',
  openTime: '営業時間',
  image: '画像',
  note: '補足メッセージ',
  delete: '削除',
  addNew: '追加',
  time: {
    min: '閉業時間は開業時間よりも大きい数字じゃなければいけません',
    max: '営業時間が無効です。もう一度ご確認ください。',
    open: '店舗の開店時間を入力してください',
    close: '店舗の閉店時間を入力してください',
  },
  result: {
    error: '店舗情報の更新に失敗しました。もう一度お試しください',
    success: '店舗情報は正常に更新されました',
  },
  price: '値段',
  errorCustomer: {
    validateEmail: '正しいフォーマットでメールアドレスを入力してください',
  },
  save: '保存',
  cancel: 'キャンセル',
  edit: '編集',
  foodDetail: {
    unit: '値段',
    delete: '削除',
    confirmDelete: '本当にこのメニューを削除してもよろしいですか？',
  },
  ok: '確認',
  validateImage: '１０MB以下のJPGもしくはPNGを選んでください',
  orderResult: {
    error: '注文の情報の更新に失敗しました。もう一度お試しください',
    error1: '店舗情報の更新に失敗しました。もう一度お試しください',
    success: '注文の情報は正常に更新されました',
    timeout: '配達員のキャンセル後60分を超えたのでもう配達員を呼ぶことができません。',
    delivery: '現在はもう配達員をキャンセルすることはできません。',
  },
  somethingWrong: '店舗情報の更新に失敗しました。もう一度お試しください',
  active: '表示',
  menuValidate: {
    validatePrice: 'メニューの値段は1桁〜15桁の数字です',
    requiredName: 'メニュー名の文字数は1〜200文字です',
    requiredImage: 'メニューのイメージ画像を追加してください',
    requireCategory: 'メニューを登録するカテゴリーを選んでください',
  },
  addNewImage: '画像の追加',
  notification: '通知',
  notifyList: '通知一覧',
  notifyDescription: '通知合計',
  notifySku: '通知番号',
  notifyTitle: 'タイトル',
  notifyEmpty: '現在は通知がありません',
  detail: '詳細',
  rowPerPage: 'ページあたりの行数',
  error300: 'サーバーがリクエストを処理できません',
  error500: 'サーバーにアクセスできません。もう一度お試しください。',
  error401: 'この機能を利用するためにはログインしてください。',
  error404: 'コンテンツは見つからないか削除された可能性があります。もう一度お試しください。',
  error400: 'コンテンツがありません。もう一度お試しください。',

  noteConfirmPhone: '住所を選択する前に電話番号を確認してください',
  retry: '確認する',
  confirm: '確認',
  errorAuthen: 'ログイン中に問題が発生しました。メールアドレスとパスワードを確認してください。',
  category: 'カテゴリー',
  language: {
    vi: 'Tiếng Việt',
    ja: '日本語',
    ko: '한국',
    en: 'English',
  },
  /** Booking setting */
  storeSetting: '店舗設定',
  regularHoliday: '定休日',
  am: '昼',
  pm: '夜',
  bookingSetting: '予約設定',
  bookingToday: '予約可能時間(時)',
  before: '前',
  bookingRange: '予約可能人数 (人) (*)',
  errorDay: '開店日を入力してください',
  placeholderMinRange: '最小: 1',
  placeholderMaxRange: '最大: 100',
  courseEmpty: 'まだコースが登録されていません',
  popular: '人気',
  errorRequiredLogin:
    'まだ予約機能利用店舗に登録されていません。Capichi運営事務局にお問い合わせください。',
  rangeBook: {
    errorRequired: '1以下の人数は入力できません',
    errorMinBook: '最小人数は最大人数より小さくなければいけません',
    errorMaxBook: '最大人数は最小人数より大きくなければいけません',
    required: '情報を全て入力してください',
  },
  resetSetting: '設定をリセット',
  notBookingDay: '予約を受け付けない日時',
  nonReservableSetting: '予約を受け付けない日時を設定',
  reservableAll: '全日',
  reservableSelect: '時間を選択',
  cannotBookingAllDay: '一日中予約ができません',
  bookingCourseList: '予約コースリスト',
  bookingCourseDescription: '全ての予約コースリストを表示する',
  bookingCourse: {
    id: '予約コースID',
    info: '予約コース情報',
    detail: '詳細',
    action: '行動',
    create: '予約コースを登録',
    delete: '予約コースを削除',
    confirmDelete: 'この予約コースを削除しますか?',
  },
  createCourse: '予約コースを登録',
  editCourse: '予約コースを修正',
  nameCourse: '予約コース名',
  priceCourse: '予約コースの値段',
  courseRange: '予約コースの予約可能人数',
  imageCourse: '予約コースの写真',
  courseError: {
    validatePrice: '予約コースの値段は1~15桁の数字です',
    requiredName: '予約コースは1~200文字です',
    requiredImage: '予約コースの写真を追加してください.',
  },
  bookingList: {
    title: '予約一覧',
    description: '予約一覧を表示',
    id: '予約ID',
    user: 'お客様情報',
    people: '予約人数',
    date: '予約日',
    time: '予約時間',
    note: '追記情報',
    course: '予約コース',
    action: '行動',
    empty: 'まだ予約はありません',
    status: 'Trạng thái',
  },
  bookingAction: {
    confirm: '確認',
    cancel: 'キャンセル',
    doneConfirm: '確定済み',
    doneCancel: 'キャンセル済み',
    dialogConfirm: {
      label: '予約を確定する',
      title: '予約を確定してもよろしいですか？',
    },
    dialogCancel: {
      label: '予約をキャンセルする',
      title: '予約をキャンセルしてもよろしいですか？',
    },
  },
  notificationBooking: {
    label: '通知一覧',
    description: '通知一覧を表示',
    id: '通知番号',
    title: 'タイトル',
    idBooking: '予約番号',
    time: '日時',
    empty: 'まだ通知はありません.',
  },
  next: '次へ',
  emptyTime: '店舗の開店日時を設定してください',
  day: {
    mon: '月',
    tue: '火',
    wed: '水',
    thu: '木',
    fri: '金',
    sat: '土',
    sun: '日',
  },
  statusBooking: {
    pending: '店舗確認待ち',
    cancel: 'キャンセル済み',
    confirm: '確定済み',
    expired: '終了済み',
    cancelByOwner: '店舗キャンセル',
    cancelByCustomer: 'お客様キャンセル',
  },
  customer: {
    name: '名前',
    email: 'メール',
    info: '顧客情報',
    phone: '電話番号',
  },
  bookingInfo: '予約情報',
  timeBooking: '予約時間',
  numberBooking: '人数',
  noteEdit: '編集の理由',
  course: 'セット',
  choose_time: '時間',
  booking: {
    time_required: '時間を選択してください。',
    reason_required: '編集の理由を入力してください。',
    success: '予約を登録しました。',
  },
  noteCancel: '予約のキャンセルの理由',
  cancelBooking: '予約をキャンセル',
  validateNoteCancel: '予約のキャンセルの理由を入力してください。',
  editBooking: '予約の情報を編集',
}

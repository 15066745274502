import { createStore, applyMiddleware } from 'redux'

import reducer from './reducers'

// const logger = store => next => action => {
//   console.log('dispatching', action)
//   const result = next(action)
//   console.log('next state info App', store.getState().languageReducer)

//   return result
// }
const middlewares = []

const store = createStore(reducer, applyMiddleware(...middlewares))
export default store

export default {
  pageTitle: 'Capichi Booking',
  storeInfo: 'Restaurant information',
  profileName: 'Profile',
  logOut: 'Log out',
  signIn: 'Log in',
  hello: 'Hello',
  validatePassword: 'Please enter password from 8 - 25 characters',
  validateField: 'Please fill out all fields.',
  password: 'Password',
  name: 'Restaurant name',
  address: 'Address',
  status: 'Status',
  shippingFee: 'Shipping fee',
  openTime: 'Opening time',
  image: 'Image',
  email: 'Email',
  note: 'Note',
  delete: 'Delete',
  addNew: 'Add new one',
  time: {
    min: 'Closing time must be greater than opening time.',
    max: 'Opening time is not validated. Please check again.',
    open: 'Please enter opening time.',
    close: 'Please enter closing time.',
  },
  result: {
    error: 'Cannot update information. Please try again later.',
    success: 'Your restaurant information has been updated successfully.',
  },
  price: 'Price',
  errorCustomer: {
    validateEmail: 'Please enter correct format of email.',
  },
  save: 'Save',
  cancel: 'Cancel',
  edit: 'Edit',
  foodDetail: {
    unit: 'Unit',
    delete: 'Delete food',
    confirmDelete: 'Are you sure deleting this booking course?',
  },
  ok: 'Yes',
  validateImage: 'Required format is JPG / PNG and size <10MB.',
  orderResult: {
    error: 'Cannot update order information. Please try again later.',
    error1: 'Cannot update information. Please try again later.',
    success: 'Order information has been updated successfully.',
    timeout: 'Cannot re-delivery because of order status.',
    delivery: 'Cannot cancel delivery because of order status.',
  },
  somethingWrong: 'Something went wrong! Please try again later.',
  active: 'Display',
  menuValidate: {
    validatePrice: 'Price per dish is limited from 1-15 characters.',
    requiredName: 'Food name is limited from 1-200 characters.',
    requiredImage: 'You need add image for the dishes.',
    requireCategory: 'Please select category for the dishes.',
  },
  addNewImage: 'Add image',
  notification: 'Notification',
  notifyList: 'Notification list',
  notifyDescription: 'Statistics for all notifications',
  notifySku: 'Notification ID',
  notifyTitle: 'Title',
  notifyEmpty: 'There is currently no notification',
  detail: 'Detail',
  rowPerPage: 'Row per page',
  error300: 'The server cannot process your request',
  error500: 'Cannot connect to server. Please try again later.',
  error401: 'Please login to do the function.',
  error404: 'The request could not be found or has been removed. Please try again later.',
  error400: 'This does not exist. Please try again later.',
  noteConfirmPhone: 'Please confirm phone number before selecting address',
  retry: 'Retry',
  confirm: 'Confirm',
  errorAuthen: 'Login error. Please check your email or password!',
  category: 'Category',
  language: {
    vi: 'Tiếng Việt',
    ja: 'Japanese',
    ko: '한국',
    en: 'English',
  },

  /** Booking setting */
  storeSetting: 'setting for restaurant',
  regularHoliday: 'Day-off',
  am: 'Lunch',
  pm: 'Dinner',
  bookingSetting: 'Setting for Booking',
  bookingToday: 'Booking available time (hour)',
  before: 'Trước',
  bookingRange: 'Limit numbers of booking (person) (*)',
  errorDay: 'Please enter opening time',
  placeholderMinRange: 'Min: 1',
  placeholderMaxRange: 'Max: 100',
  courseEmpty: 'There is currently no course set.',
  popular: 'Popular',
  errorRequiredLogin:
    'You have not registered an account for Booking. Please contact Capichi customer service',
  rangeBook: {
    errorRequired: 'Number of people must not be less than 1',
    errorMinBook: 'The minimum number of people must be less than the maximum number of people',
    errorMaxBook: 'The maximum number of people must be greater than the minimum number of people',
    required: 'Please enter full data',
  },
  resetSetting: 'Delete setting',
  notBookingDay: 'Booking unavailable day',
  nonReservableSetting: 'Settings for the date and time cannot be booked',
  reservableAll: 'Alldays',
  reservableSelect: 'Select time',
  cannotBookingAllDay: 'Cannot set booking alldays',
  bookingCourseList: 'Booking set list',
  bookingCourseDescription: 'Display the set of dishes of restaurant',
  bookingCourse: {
    id: 'Set dishes ID',
    info: 'Set dishes information',
    detail: 'Detail',
    action: 'Action',
    create: 'Create set of dishes',
    delete: 'Delete Set of dishes',
    confirmDelete: 'Are you sure deleting this set of dishes?',
  },
  createCourse: 'Create set of dishes',
  editCourse: 'Edit set of dishes',
  nameCourse: 'Set dishes name',
  priceCourse: 'Set dishes price',
  courseRange: 'Limit the number of people',
  imageCourse: 'Set dishes image',
  courseError: {
    validatePrice: 'Set dishes price is limited from 1-15 characters.',
    requiredName: 'Set dishes name is limited from 1-200 characters.',
    requiredImage: 'You need adding image for set dishes.',
  },
  bookingList: {
    title: 'Booking list',
    description: 'Show Booking list',
    id: 'Booking ID',
    user: 'Customer detail',
    people: 'Customer number',
    date: 'Booking date',
    time: 'Booking time',
    note: 'Note',
    course: 'Set dishes',
    action: 'Action',
    empty: 'Currently there is no booking order at your restaurant.',
    status: 'Trạng thái',
  },
  bookingAction: {
    confirm: 'Confirm',
    cancel: 'Cancel',
    doneConfirm: 'Confirmed',
    doneCancel: 'Canceled',
    dialogConfirm: {
      label: 'Confirm Booking',
      title: 'Are you sure confirming Booking?',
    },
    dialogCancel: {
      label: 'Cancel Booking',
      title: 'Are you sure canceling Booking?',
    },
  },
  notificationBooking: {
    label: 'Notification list',
    description: 'Display notification list',
    id: 'Notification ID',
    title: 'Title',
    idBooking: 'Booking ID',
    time: 'Time',
    empty: 'Currently there is no notification.',
  },
  next: 'Next',
  emptyTime: 'Please set opening time',
  day: {
    mon: 'Mon',
    tue: 'Tues',
    wed: 'Wed',
    thu: 'Thurs',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
  },
  statusBooking: {
    pending: 'Pending',
    cancel: 'Canceled',
    confirm: 'Confirmed',
    expired: 'Out of time',
    cancelByOwner: 'Restaurant canceled',
    cancelByCustomer: 'Customer canceled',
  },
  customer: {
    name: 'Customer name',
    email: 'Email',
    info: 'Customer information',
    phone: 'Phone number',
  },
  bookingInfo: 'Booking information',
  timeBooking: 'Booking time',
  numberBooking: 'People',
  noteEdit: 'Edit reason',
  course: 'Course',
  choose_time: 'Time',
  booking: {
    time_required: 'Please select reservation time.',
    reason_required: 'Please enter the reason for editing.',
    success: 'Your reservation is updated successfully.',
  },
  noteCancel: 'Reason for cancelling booking',
  cancelBooking: 'Cancel booking',
  validateNoteCancel: 'Please enter the reason for booking.',
  editBooking: 'Edit Booking',
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import { getBadgetNotification } from '../../api'
import { AUTHEN_TOKEN } from '../../constants/define'
// import MailIcon from '@material-ui/icons/Mail'

class BadgeNotification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      numberBadge: 0,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.getBadge()
    }, 500)
    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', message => {
        try {
          // console.log(message)
          const { data } = message.data.firebaseMessaging.payload
          const unread = data['gcm.notification.unread_count'] || 0
          this.setState({ numberBadge: unread })
        } catch (error) {
          // console.log(error)
        }
      })
    }
  }

  getBadge = () => {
    const authen = localStorage.getItem(AUTHEN_TOKEN)
    getBadgetNotification({
      authen,
    }).then(result => {
      const { isSuccess, message, data } = result
      if (!isSuccess) {
        console.log(message)
      } else {
        this.setState({ numberBadge: data.unread_count })
      }
    })
  }

  render() {
    const { numberBadge } = this.state
    const { classes } = this.props
    return (
      <Badge badgeContent={numberBadge} className={classes.container} color="error">
        <NotificationsActiveIcon />
      </Badge>
    )
  }
}

const styles = {
  container: {
    marginRight: 18,
  },
}

BadgeNotification.propTypes = {
  classes: PropTypes.any,
}

export default withStyles(styles)(BadgeNotification)
